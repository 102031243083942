import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CheckCnpValidatorDirective } from './check-cnp-validator.directive';
import { FilterInputCharactersDirective } from './filter-input-characters.directive';
import { MatchValidatorDirective } from './match-validator.directive';
import { NotMatchValidatorDirective } from './not-match-validator.directive';
import { PasswordValidatorDirective } from './password-validator.directive';
import { ValidationSummaryLabelComponent } from './validation-summary-label.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        FilterInputCharactersDirective,
        CheckCnpValidatorDirective,
        PasswordValidatorDirective,
        MatchValidatorDirective,
        NotMatchValidatorDirective,
        ValidationSummaryLabelComponent,
    ],
    exports: [
        FilterInputCharactersDirective,
        CheckCnpValidatorDirective,
        PasswordValidatorDirective,
        MatchValidatorDirective,
        NotMatchValidatorDirective,
        ValidationSummaryLabelComponent,
    ],
})
export class ValidatorsModule {
    public static forRoot(): ModuleWithProviders<ValidatorsModule> {
        return {
            ngModule: ValidatorsModule,
            providers: [],
        };
    }
}
