import { Injectable, Injector, Type } from '@angular/core';

import camelCase from 'lodash-es/camelCase';
import cloneDeep from 'lodash-es/cloneDeep';

import { ModularFormContext } from './models/context-base';

export interface ModularFormDataConverter {
    convert(data: any, context: ModularFormContext): void;
}

@Injectable({
    providedIn: 'root',
})
export class DataConverterService {
    static converters: Map<string, Type<ModularFormDataConverter>> = new Map();

    constructor(private injector: Injector) {}

    convert(data: any, context: ModularFormContext) {
        const converterNames: string[] = context.config.main.converters;
        data = cloneDeep(data);

        converterNames
            .map((n) => DataConverterService.converters.get(n))
            .filter((t) => t)
            .map((t) => this.injector.get(t) as ModularFormDataConverter)
            .map((c) => c.convert(data, context));

        return data;
    }
}

export function DataConverter(converterName: string) {
    return (target: any) => {
        DataConverterService.converters.set(camelCase(converterName), target);
    };
}
