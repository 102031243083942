import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PlainLinkComponent } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { ValidationMessagesComponent } from '@frontend/vanilla/shared/forms';

import { ManualNumberSelectComponent } from './manual-number-select.component';

@NgModule({
    imports: [CommonModule, FormsModule, TrustAsHtmlPipe, PlainLinkComponent, ValidationMessagesComponent],
    exports: [ManualNumberSelectComponent],
    declarations: [ManualNumberSelectComponent],
})
export class ManualNumberSelectModule {}
