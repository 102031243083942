import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ProgressBarComponent],
    exports: [ProgressBarComponent],
})
export class ProgressBarModule {
    public static forRoot(): ModuleWithProviders<ProgressBarModule> {
        return {
            ngModule: ProgressBarModule,
            providers: [],
        };
    }
}
