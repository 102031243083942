import { getCurrencySymbol } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, NgModel, Validator } from '@angular/forms';

import { UserService } from '@frontend/vanilla/core';
import { ValidationMessagesComponent } from '@frontend/vanilla/shared/forms';

import { AccountLimit } from '../account-limit/account-limit.models';
import { PortalTrackingService } from '../tracking/tracking-resource.service';
import { TrackingEvent } from '../tracking/tracking.model';

export interface ManualSelectOption {
    text: string;
    value: string;
    numberValue: number;
}

@Component({
    selector: 'pt-manual-number-select',
    templateUrl: 'manual-number-select.component.html',
    host: {
        '[class.manual-number-select]': 'true',
    },
    providers: [
        { provide: NG_VALIDATORS, useExisting: ManualNumberSelectComponent, multi: true },
        { provide: NG_VALUE_ACCESSOR, useExisting: ManualNumberSelectComponent, multi: true },
    ],
})
export class ManualNumberSelectComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {
    @Input() options: ManualSelectOption[];
    @Input() validationMessagesRef?: ValidationMessagesComponent;
    @Input() selectPlaceholder: string;
    @Input() inputPlaceholder: string;
    @Input() allowZeroInput: boolean = true;
    @Input() limit: AccountLimit;
    @Input() franceLimitSuggestionEnabled?: boolean;
    @Input() showCurrency?: boolean;
    @Input() hoursText?: string;
    @Input() currentIndex?: number;
    @Input() fromInterceptor?: boolean;
    @Input() limitName?: string;
    @Input() content: any;
    @Input() validationMsg: any;
    @Input() formControl: AbstractControl;
    @Output() fieldBlurred: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('select') select: NgModel;
    @ViewChild('input') input: NgModel;
    @ViewChild('inputRef') inputRef: ElementRef;
    userCurrency: string;
    disabled: boolean;
    selectedValue?: ManualSelectOption | null = null;
    manualValue: any;
    inputValue: any;
    manualOption?: ManualSelectOption;
    get isManual() {
        return this.franceLimitSuggestionEnabled ? true : this.selectedValue === this.manualOption;
    }
    private initialValue: number;
    private ngControl: NgControl | null;

    onChange: (value: any) => void = () => {};
    onTouched = () => {};

    constructor(
        private injector: Injector,
        private user: UserService,
        private portalTrackingService: PortalTrackingService,
    ) {}

    ngOnInit() {
        this.ngControl = this.injector.get(NgControl, null);
        this.options.map((o) => (o.numberValue = parseInt(o.value)));
        this.manualOption = this.options.find((o) => o.numberValue === -1);
        this.userCurrency = getCurrencySymbol(this.user.currency, 'wide');
        if (this.initialValue != null) {
            this.setValue(this.initialValue);
        }
        this.manualValue = this.franceLimitSuggestionEnabled && this.limit.originalAmount! > 0 ? this.limit.originalAmount : this.manualValue;
        if (this.franceLimitSuggestionEnabled && !this.fromInterceptor) this.inputValue = this.manualValue;
    }

    ngAfterViewInit(): void {
        this.applyChildValidity();
        if (this.ngControl) this.ngControl.statusChanges!.subscribe(() => this.applyChildValidity());
    }

    onSelect() {
        if (this.limit && this.limit.product) {
            const eventDetails = this.isManual ? 'Enter Manually' : this.selectedValue?.value == '0' ? 'No Limit' : this.selectedValue?.numberValue;
            this.trackFieldEvents('stake limits', 'click', 'weekly', 'stake limits', eventDetails ? eventDetails.toString() : '', this.limit.product);
        }
        if (!this.isManual && this.selectedValue!.value == '0') this.manualValue = '';
        else if (!this.isManual && this.selectedValue) this.manualValue = this.selectedValue.value;

        this.propagateChange();
    }

    onManualInput(val?: string) {
        if (val!) {
            if (this.user.claims.get('language') === 'ES') {
                if (val.includes('.')) {
                    val = val.split('.').join('');
                    this.manualValue = val;
                }
            }
        }
        if (!this.allowZeroInput && val == '0') this.manualValue = '';
        this.propagateChange();
    }

    onBlur(fromSelect?: boolean) {
        if (this.validationMessagesRef) {
            this.validationMessagesRef.setFocused(false);
        }

        if (!fromSelect) {
            this.onTouched();
            this.fieldBlurred.emit(true);
        }

        if (!this.isManual) {
            this.onTouched();
        }
    }

    onFocus() {
        if (this.validationMessagesRef) {
            this.validationMessagesRef.setFocused(true);
        }
    }
    onFocusOut() {
        if (this.franceLimitSuggestionEnabled && this.manualValue != this.inputValue) {
            this.trackEvents('manualSelect');
            this.inputValue = this.manualValue;
        }
    }

    validate(): { [key: string]: any } | null {
        if (this.isBadManualInput()) {
            return { number: true };
        }

        return null;
    }

    writeValue(obj: number): void {
        if (!this.options) {
            // this is called before the options are initialized
            this.initialValue = obj;

            return;
        }
        if (!this.franceLimitSuggestionEnabled) this.setValue(obj);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private isBadManualInput() {
        if (this.inputRef) {
            const element: HTMLInputElement = this.inputRef.nativeElement;
            if (element.validity.badInput) {
                return true;
            }
        }

        return false;
    }

    private applyChildValidity() {
        setTimeout(() => {
            if (!this.franceLimitSuggestionEnabled) this.select.control.setErrors(null);
            if (this.input) {
                this.input.control.setErrors(null);
            }

            if (this.ngControl && !this.ngControl.valid) {
                if (this.isManual) {
                    this.input.control.setErrors({ invalid: true });
                } else {
                    this.select.control.setErrors({ invalid: true });
                }
            }
        });
    }

    private setValue(value: number) {
        if (value == null) {
            this.selectedValue = null;
        } else {
            const option = this.options.find((o) => o.numberValue === value);

            if (option) {
                this.selectedValue = option;
            } else {
                this.selectedValue = this.manualOption;
                this.manualValue = '' + value;
            }
        }
    }

    private propagateChange() {
        if (this.isManual) {
            if (this.isBadManualInput()) {
                this.onChange(NaN);
            } else {
                this.onChange(this.manualValue);
            }
        } else {
            this.onChange(this.selectedValue!.numberValue);
        }
    }
    setDepositValue(value: any) {
        this.manualValue = this.inputValue = value;
        this.onManualInput(value);
        this.trackEvents('suggestionClick');
    }
    private trackEvents(action: string): void {
        let trackingEvent: TrackingEvent = {};
        const commonTrackingEvent: TrackingEvent = {
            CategoryEvent: 'gambling controls',
            LabelEvent: this.fromInterceptor ? 'step rg set limits interceptor registration' : this.limitName,
            LocationEvent: this.fromInterceptor ? 'step rg set limits interceptor registration' : this.limitName,
            ActionEvent: 'click',
            ContentPosition: this.fromInterceptor ? this.currentIndex! + 1 : 'not applicable',
            PositionEvent: this.limitName,
            Value: this.manualValue,
        };
        switch (action) {
            case 'suggestionClick':
                trackingEvent = {
                    EventDetails: 'Suggested value click',
                };
                break;
            case 'manualSelect':
                trackingEvent = {
                    EventDetails: 'user input',
                };
                break;
            default:
                break;
        }
        if (this.franceLimitSuggestionEnabled) {
            trackingEvent = {
                Product:
                    this.limitName == 'deposit limits'
                        ? 'all'
                        : this.limit.limitName == 'Stakes limit for poker' || this.limit.limitName == 'Limite de mises pour le poker'
                          ? 'poker'
                          : this.limit.limitName == 'Stakes limit for sports betting' ||
                              this.limit.limitName == 'Limite de mises pour les paris sportifs'
                            ? 'sports'
                            : this.limitName == 'poker session limits'
                              ? 'poker'
                              : 'not applicable',
            };
        }
        this.portalTrackingService.trackEvents({ ...commonTrackingEvent, ...trackingEvent }, '', 'Event.Value');
    }
    private trackFieldEvents(
        labelEvent: string,
        actionEvent: string,
        positionEvent: string,
        locationEvent: string,
        eventDetails: string,
        product: string,
    ) {
        const trackingEvents: TrackingEvent = {
            CategoryEvent: 'Gambling Controls',
            LabelEvent: labelEvent,
            ActionEvent: actionEvent,
            PositionEvent: positionEvent,
            LocationEvent: locationEvent,
            EventDetails: eventDetails,
            Product: product,
            Value: eventDetails,
            UrlClicked: 'not applicable',
        };
        this.portalTrackingService.trackEvents({ ...trackingEvents }, '', 'Event.Tracking');
    }
}
