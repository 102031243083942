import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { Validator, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[ptMatch][formControlName],[ptMatch] [formControl],[ptMatch][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MatchValidatorDirective, multi: true }],
})
export class MatchValidatorDirective implements Validator, OnChanges {
    @Input() ptMatch: any;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ptMatch && changes.ptMatch.currentValue) {
            this.ptMatch.valueChanges.subscribe(() => {
                const control = this.ptMatch.root.get(this.elementRef.nativeElement.name);
                this.validate(control);
            });
        }
    }

    validate(c: UntypedFormControl): { [key: string]: any } | null {
        // self value (e.g. retype password)
        const value = c.value;
        // control value (e.g. password)
        // let otherElement = c.root.get(this.ptMatch);
        // value not equal
        if (this.ptMatch && value !== this.ptMatch.value) {
            c.setErrors({ match: false });
            return {
                match: false,
            };
        }
        if (c.errors) {
            delete c.errors['match'];
            if (!Object.keys(c.errors).length) c.setErrors(null);
        }
        return null;
    }
}
