import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ScrollViewComponent } from './scroll-view.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ScrollViewComponent],
    exports: [ScrollViewComponent],
})
export class ScrollViewModule {
    public static forRoot(): ModuleWithProviders<ScrollViewModule> {
        return {
            ngModule: ScrollViewModule,
            providers: [],
        };
    }
}
