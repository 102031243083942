import { Injectable } from '@angular/core';
import { OnAppInit } from '@frontend/vanilla/core';

import { ModularSubComponentRegistryServiceNew } from '../modular-forms/modular-sub-component-registry.service';
import { FieldsetComponent } from './sub-components/fieldset.component';
import { RowComponent } from './sub-components/row.component';

@Injectable()
export class GenericComponentBootstrapService implements OnAppInit {
    constructor(private modularSubComponentRegistryServiceNew: ModularSubComponentRegistryServiceNew) {}

    onAppInit() {
        this.modularSubComponentRegistryServiceNew.register('RowComponent', RowComponent);
        this.modularSubComponentRegistryServiceNew.register('FieldsetComponent', FieldsetComponent);
    }
}
