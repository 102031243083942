import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[ptStatusColor]',
})
export class StatusColorDirective implements OnChanges {
    @Input() daysLeft: number;
    @Input() input: any;
    @Input() colorStatusObj: any;

    constructor(private el: ElementRef) {
        this.pickColor();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.input) {
            this.pickColor();
        }
    }

    private pickColor() {
        if (this.colorStatusObj) {
            for (let i = 0; i < this.colorStatusObj.colors.length; i++) {
                if (this.input <= this.colorStatusObj.statusDurations[i]) {
                    if (this.el.nativeElement.tagName.toUpperCase() == 'PATH') {
                        this.el.nativeElement.style.stroke = this.colorStatusObj.colors[i];
                        break;
                    } else {
                        this.el.nativeElement.style.fill = this.colorStatusObj.colors[i];
                        break;
                    }
                }
            }
        }
    }
}
