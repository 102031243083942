@if (!franceLimitSuggestionEnabled) {
    <div class="selectmanual-select">
        <select
            class="form-control form-control-select w-100 please-select"
            #select="ngModel"
            [disabled]="disabled"
            [(ngModel)]="selectedValue"
            (ngModelChange)="onSelect()"
            (blur)="onBlur(true)"
            (focus)="onFocus()">
            <option [value]="null" disabled hidden>{{ selectPlaceholder }}</option>
            @for (option of options; track $index) {
                <option [ngValue]="option">{{ option.text }}</option>
            }
        </select>
    </div>
}
@if (isManual && !franceLimitSuggestionEnabled) {
    <div class="selectmanual-input pt-2">
        <input
            #input="ngModel"
            #inputRef
            type="text"
            [disabled]="disabled"
            [placeholder]="inputPlaceholder"
            [(ngModel)]="manualValue"
            (ngModelChange)="onManualInput($event)"
            (blur)="onBlur(false)"
            (focus)="onFocus()"
            class="form-control w-100" />
    </div>
}
@if (isManual && franceLimitSuggestionEnabled) {
    <div class="selectmanual-input pt-2">
        <div class="form-control-container">
            <input
                #input="ngModel"
                #inputRef
                type="text"
                [disabled]="disabled"
                [placeholder]="inputPlaceholder"
                [(ngModel)]="manualValue"
                (ngModelChange)="onManualInput($event)"
                (blur)="onBlur(false)"
                (focus)="onFocus()"
                class="currency-padding form-control w-100"
                onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 0)"
                (focusout)="onFocusOut()" />
            @if (showCurrency && manualValue > 0) {
                <div class="currency-symbol mr-4">{{ userCurrency }}</div>
            }
            @if (!showCurrency && manualValue > 0) {
                <div class="user-currency left-50" [innerHtml]="hoursText"></div>
            }
        </div>
    </div>
}
@if (franceLimitSuggestionEnabled) {
    <lh-validation-messages [formElement]="formControl" [messages]="validationMsg"> </lh-validation-messages>
    <div class="limit-actions form-layout-horizontal float-left txt-s mt-2">
        @if (content.messages['MaxLimitMet']) {
            <div class="maxLimit">
                {{ limit.amount == 9999999 || limit.originalAmount == 9999999 ? content.messages['MaxLimitMet'] : '' }}
            </div>
        }
    </div>
    <div class="selectmanual-select">
        <div>
            <div class="row pb-2 w-110 mt-2 pl-1 pr-2">
                @for (item of options; track i; let i = $index) {
                    <div class="pl-1 pill-styles">
                        <a
                            class="dh sports-pill sports-pill-theme w-100 font-weight-bold pills-padding"
                            [ngClass]="{ active: manualValue == item.value, disabled: disabled }"
                            style="border-radius: 4px; border: 1px solid var(--body-body-100, #2b2b2b)"
                            (click)="setDepositValue(item.value)"
                            >{{ item.text }}{{ showCurrency ? userCurrency : '' }}</a
                        >
                    </div>
                }
            </div>
        </div>
    </div>
}
