import { Component, Input } from '@angular/core';
import { CommonMessages } from '@frontend/vanilla/core';

@Component({
    selector: 'pt-progress-bar',
    templateUrl: 'progress-bar.component.html',
})
export class ProgressBarComponent {
    /** Indicates the value of progress bar. % sign is appended if text is not provided */
    @Input() value: number;
    /** If provided, it will be displayed instead default 'value + %'  */
    @Input() text: string;

    constructor(public commonMessages: CommonMessages) {}

    get displayText() {
        return this.text ? this.text : this.value.toFixed(0) + '%';
    }
}
