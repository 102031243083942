import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptCustomPattern]',
})
export class CustomPatternDirective {
    @Input() pattern: string;
    constructor(private control: NgControl) {}

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent) {
        const element = event.target as HTMLInputElement;
        const newValue = element.value.replace(this.pattern, '');
        if (newValue !== element.value) {
            this.control.control!.setValue(newValue);
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
            return;
        }
        if (event.key && event.key.length === 1 && event.key.match(this.pattern)) {
            event.preventDefault();
        }
    }
}
