import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[ptFocus]',
})
export class FocusDirective implements AfterViewInit {
    private el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 200);
    }
}
