import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { Validator, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[ptNotMatch][formControlName],[ptNotMatch][formControl],[ptNotMatch][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NotMatchValidatorDirective, multi: true }],
})
export class NotMatchValidatorDirective implements Validator, OnChanges {
    @Input() ptNotMatch: any;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ptNotMatch && changes.ptNotMatch.currentValue) {
            this.ptNotMatch.valueChanges.subscribe(() => {
                const control = this.ptNotMatch.root.get(this.elementRef.nativeElement.name);
                this.validate(control);
            });
        }
    }

    validate(c: UntypedFormControl): { [key: string]: any } | null {
        const value = c.value;
        if (this.ptNotMatch && value == this.ptNotMatch.value) {
            c.setErrors({ notmatch: false });
            return {
                notmatch: false,
            };
        }
        if (c.errors) {
            delete c.errors['notmatch'];
            if (!Object.keys(c.errors).length) c.setErrors(null);
        }
        return null;
    }
}
