import { NgModule, ModuleWithProviders } from '@angular/core';

import { AlphabetsAllowedOnlyDirective } from './alphabets.directive';
import { StopPropagationDirective } from './click-stop.directive';
import { CustomPatternDirective } from './customPattern.directive';
import { DisableCopyPasteDirective } from './disable-copy-paste.directive';
import { FocusDirective } from './focus.directive';
import { DateInUsersTimezonePipe } from './in-user-timezone.directive';
import { MaximumLengthLimitDirective } from './maxlength-limit.directive';
import { NumberAllowedOnlyDirective } from './number.directive';
import { PortalPrefixCharDirective } from './prefix-char.directive';
import { PreventBackspaceDirective } from './prevent-backspace.directive';
import { PreventOnlySpaceDirective } from './prevent-only-spaces.directive';
import { UppercaseDirective } from './uppercase.directive';

const directives = [
    UppercaseDirective,
    StopPropagationDirective,
    DateInUsersTimezonePipe,
    NumberAllowedOnlyDirective,
    AlphabetsAllowedOnlyDirective,
    FocusDirective,
    PreventBackspaceDirective,
    PreventOnlySpaceDirective,
    MaximumLengthLimitDirective,
    DisableCopyPasteDirective,
    PortalPrefixCharDirective,
    CustomPatternDirective,
];
@NgModule({
    declarations: directives,
    exports: directives,
})
export class DirectivesModule {
    public static forRoot(): ModuleWithProviders<DirectivesModule> {
        return {
            ngModule: DirectivesModule,
        };
    }
}
