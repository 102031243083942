import { ClientConfig, ClientConfigProductName, LazyClientConfig, LazyClientConfigBase } from '@frontend/vanilla/core';
import { TopMenuVisibility } from '@frontend/vanilla/features/navigation-layout';

import { KycInfoModel } from '../manual-doc-upload-revamp/manual-doc-uploader.model';

@LazyClientConfig({ key: 'ptHomePageConfig', product: ClientConfigProductName.PORTAL })
export class HomePageConfig extends LazyClientConfigBase {
    isVanillaHomePageEnabled: boolean;
}

@LazyClientConfig({ key: 'ptBonusHistoryConfig', product: ClientConfigProductName.PORTAL })
export class BonusHistoryConfigClientConfig extends LazyClientConfigBase {
    cutOffYear: number;
    cutOffMonth: number;
    pageSize: number;
    amountOfDisplayedSingleMonths: number;
    dateCollectionLastYear: number;
}

export interface Config {
    device: string;
    url: string;
    useIdNow: boolean;
}

export interface IdNowNativeApp {
    product: string;
    config: Config;
}

export interface IdNowDeviceVersion {
    device: string;
    version: string;
}

@LazyClientConfig({ key: 'ptAccountVerificationConfig', product: ClientConfigProductName.PORTAL })
export class AccountVerificationClientConfig extends LazyClientConfigBase {
    useIdNowNativeAppConfig: IdNowNativeApp[];
    idNowDeviceVersion: IdNowDeviceVersion;
    idNowNativeDefault: string;
}

@ClientConfig({ key: 'ptUpload', product: ClientConfigProductName.PORTAL })
export class UploadConfig {
    allowedFileTypes: string;
    allowedExtensionsAndMimeTypes: { [key: string]: string };
    maxFileSizeInKb: number;
    maxUserFileSizeInKb: number;
    showToasterOnUploadSuccess: boolean;
    cashierTriggerPoints: string;
    multiDocMaxUserFileSizeInKb: number;
    allowedRoutes: { [key: string]: { [key: string]: string[] } };
    documentHistoryEnabled: boolean;
    isSiOrBinaryStandards: boolean;
    multiDocUploadV2: boolean;
}

@LazyClientConfig({ key: 'ptMonthlyOverView', product: ClientConfigProductName.PORTAL })
export class MonthlyOverviewClientConfig extends LazyClientConfigBase {
    enabled: boolean;
}

@LazyClientConfig({ key: 'ptManualDocUploadRevamp', product: ClientConfigProductName.PORTAL })
export class ManualDocUploadRevampConfig extends LazyClientConfigBase {
    isEnabled: boolean;
    composition: KycInfoModel;
    isEnabledForDormantAccount: boolean;
}

@LazyClientConfig({ key: 'ptPasswordConfig', product: ClientConfigProductName.PORTAL })
export class PasswordConfig extends LazyClientConfigBase {
    invalidBirthdayFormats: string[];
    displayNewPasswordHintValidation: boolean;
    isNewPasswordValidationEnabled: boolean;
    isPersonalInfoAllowedInPassword: boolean;
    isNewPasswordValidationHints: boolean;
    isPasswordValidationMessage: boolean;
    isPasswordHintSimplificationEnabled: boolean;
    isUserIdValidationEnabled: boolean;
    isLetterAndUpperLower: boolean;
    passAdditionalParams: boolean;
    enableHintsOnFocus: boolean;
    enableProgressbar: boolean;
    enableCapsLock: boolean;
    passwordValidationCriteria: { [key: string]: boolean };
    enableStrongPwdSuggestion: { [key: string]: string };
}

@LazyClientConfig({ key: 'ptLostPasswordConfig', product: ClientConfigProductName.PORTAL })
export class LostPasswordClientConfig extends LazyClientConfigBase {
    otpEnabled: boolean;
    showForgotPasswordLink: boolean;
    showForgotUserIdLink: boolean;
    showPullChat: boolean;
    requiresSecondSecurityQuestion: boolean;
}

@ClientConfig({ key: 'ptDobClientConfig', product: ClientConfigProductName.PORTAL })
export class DobClientConfig {
    isEnabled: boolean;
    isNewDOBEnabled: { [key: string]: boolean };
    corBasedAgeLimit: { [key: string]: string };
}

@LazyClientConfig({ key: 'ptLimitsConfig', product: ClientConfigProductName.PORTAL })
export class LimitsClientConfig extends LazyClientConfigBase {
    waitingPeriodFormat: string;
    enablePendingLimit: boolean;
    isOnlyMonthlyLimitSupported: boolean;
    enableReviewPendingMessage: boolean;
    isClearLimitsSupported: boolean;
    rgLimitsNewPhaseOne: string[];
    // rgLimitsNewPhaseOne: {[key: string]: {[key: string] : string|number|boolean}};
}

@LazyClientConfig({ key: 'ptCashbackConfig', product: ClientConfigProductName.PORTAL })
export class CashbackClientConfig extends LazyClientConfigBase {
    overviewHistoryVisibility: string;
    type: string;
    isNewUIEnabled: boolean;
}

@LazyClientConfig({ key: 'ptContactConfig', product: ClientConfigProductName.PORTAL })
export class ContactConfigConfig extends LazyClientConfigBase {
    ContactOptionsPhase2: any;
}

@ClientConfig({ key: 'ptForceInterceptorConfig', product: ClientConfigProductName.PORTAL })
export class ForceInterceptorConfig {
    isEnabled: true;
    restrictedUrls: any;
    isUSNJ: boolean;
}

@LazyClientConfig({ key: 'ptFastRegistrationConfig', product: ClientConfigProductName.PORTAL })
export class FastRegistrationClientConfig extends LazyClientConfigBase {
    countries: string[];
    fastRegistrationEnabled: boolean;
    isABTestEnabled: boolean;
}

@LazyClientConfig({ key: 'ptSourceOfFunds', product: ClientConfigProductName.PORTAL })
export class SourceOfFundsClientConfig extends LazyClientConfigBase {
    sourceOfFundsEnabled: boolean;
}

@LazyClientConfig({ key: 'ptSpendingControls', product: ClientConfigProductName.PORTAL })
export class SpendingControlsClientConfig extends LazyClientConfigBase {
    depositCurfewEnabled: boolean;
    withdrawBlockerEnabled: boolean;
    spendingControlsEnabled: boolean;
    isMaxStakeLimitsEnabled: boolean;
    depositLimitsEnabled: boolean;
    gamingLimitsEnabled: boolean;
    depositLimitsRedesignEnabled: boolean;
    lossLimitsVersion: number;
    greeceDepositLimitsEnabled: boolean;
}

@ClientConfig({ key: 'ptAccountRecoveryClientConfig', product: ClientConfigProductName.PORTAL })
export class AccountRecoveryClientConfig {
    emailRegex: string;
    enableAutoLogin: boolean;
    enableNewEmailVerificationAPIs: boolean;
    showSuccessScreen: boolean;
    passAdditionalParamsV2: boolean;
    enableRememberMe: boolean;
    disableLastVisitorFlow: boolean;
}

@LazyClientConfig({ key: 'ptPersonalDetailsConfig', product: ClientConfigProductName.PORTAL })
export class PersonalDetailsClientConfig extends LazyClientConfigBase {
    isAccessAllowed: boolean;
    IsMaskingEnable: boolean;
}

@LazyClientConfig({ key: 'ptGamblingControls', product: ClientConfigProductName.PORTAL })
export class GamblingControlsClientConfig extends LazyClientConfigBase {
    enableV2: boolean;
    enabledComponents: { [component: string]: boolean };
    franceCompliantEnhancement: boolean;
    borisGamblingControlsV2: boolean;
    isTimeoutOrSelfexclusionEnabled: boolean;
    enablePlayBreak: boolean;
    selfExclusionV3: boolean;
    isSingleOptionACEnabled: boolean;
    isAccountClosureWAenabled: boolean;
    isFranceGCDesignEnabled: boolean;
    enableServiceClosureRedirection: ServiceClosureConfigs;
}

@ClientConfig({ key: 'ptAccountMenuV2Config', product: ClientConfigProductName.PORTAL })
export class AccountMenuV2ClientConfig {
    toggleSourceItem: string[];
    v3ToggleSourceItem: {
        [key: string]: TopMenuVisibility;
    }[];
}

@LazyClientConfig({ key: 'ptKba', product: ClientConfigProductName.PORTAL })
export class KbaClientConfig extends LazyClientConfigBase {
    isEnabled: boolean;
    isV2Enabled: boolean;
    showUpload: boolean;
    maxAttempts: number;
    timer: any;
}

@ClientConfig({ key: 'ptZendeskClientConfig', product: ClientConfigProductName.PORTAL })
export class ZendeskClientConfig {
    enabled: boolean;
    sunshineConfig: any;
    removeLiveChat: boolean;
    showChatAsLink: {
        [key: string]: boolean;
    };
}

@ClientConfig({ key: 'ptCommon', product: ClientConfigProductName.PORTAL })
export class CommonClientConfig {
    shouldReuseRoute: boolean;
    enableUsSingleAccountText: boolean;
    enableBrowserAutocomplete: { [area: string]: boolean };
    triggerGoogleTagManagerClientInjection: boolean;
    googleTagManagerInjectionDelay: number;
    commonPortalMessages: any;
    chartsScriptUrl: string;
    chartsOptions: any;
    portalComponentSelectors: string[];
    appsSchema: string;
    scriptsInjectionData: { [key: string]: { url: string; customAttributes: { [key: string]: string }; styleSheetUrl: string } };
    cardVerificationUseCase: string;
    scrolledToBottomPadding: number;
    version: number;
    apiBaseUrl: string;
    enableSkeleton: boolean;
    isGcRedesignEnabled: boolean;
    enableLoadingV2: boolean;
    enableUSloader: boolean;
    unicodeRegex: string;
    unicodeComponentSelector: string[];
    apiTrackingData: any;
    pieChartColors: string[];
    guideEnabled: boolean;
    enableGreeceComplaintForm: boolean;
    showContactOptionsBySource: any;
    landingPage: any;
    guideConfig: any;
    enableFullPageRedirection: any;
    customerHubMenuVersions: number[];
    trimExtraSpaces: boolean;
}

@LazyClientConfig({ key: 'ptGreekToLatin', product: ClientConfigProductName.PORTAL })
export class GreekToLatinConfig extends LazyClientConfigBase {
    greekToLatinCharactersMapping: any;
}

@LazyClientConfig({ key: 'ptSelfExclusion', product: ClientConfigProductName.PORTAL })
export class SelfExclusionConfig extends LazyClientConfigBase {
    oneClickExclude: any;
    oneClickExcludeEnabled: boolean;
}

@LazyClientConfig({ key: 'ptRiskAssessmentConfig', product: ClientConfigProductName.PORTAL })
export class RiskAssessmentClientConfig extends LazyClientConfigBase {
    isEnabled: boolean;
    scriptUrl: string;
    mode: string;
    registrationStep: number;
    threatMetrixSiteKey: string;
    threatMetrixDomain: string;
    submitLNTokenWithRegistration: boolean;
}

@ClientConfig({ key: 'ptUkgcKyc', product: ClientConfigProductName.PORTAL })
export class UkgcKycConfig {
    enableDeKycWithSchufa: boolean;
    enableJumioKycRefresh: boolean;
    enableRevampedJumioFlow: boolean;
}

@LazyClientConfig({ key: 'ptCharts', product: ClientConfigProductName.PORTAL })
export class ChartsClientConfig extends LazyClientConfigBase {
    axisIntervals: any;
    readonly chartsOptions: any;
    chartsScriptUrl: string;
    colorSchema: { [key: string]: any };
    fontSchema: { [key: string]: any };
}
@LazyClientConfig({ key: 'ptDeKyc', product: ClientConfigProductName.PORTAL })
export class DeReKycConfig extends LazyClientConfigBase {
    enableDeAnnualReKyc: boolean;
    enableKycUnVerifiedFieldsSteps: boolean;
}

@LazyClientConfig({ key: 'ptSocial', product: ClientConfigProductName.PORTAL })
export class SocialClientConfig extends LazyClientConfigBase {
    itsMeAdditionalConfigurations: any;
    socialRegistrationProviders: { [key: string]: boolean };
    socialRegistrationQueryParameters: { [key: string]: any };
}

@LazyClientConfig({ key: 'ptPreRegistrationConfigsConfig', product: ClientConfigProductName.PORTAL })
export class PreRegistrationClientConfigs extends LazyClientConfigBase {
    isNvPreRegScreenEnabled: boolean;
    iDinIntegrationEnabled: boolean;
    isRegistrationPageBlock: boolean;
}

@LazyClientConfig({ key: 'ptBudgetCalculator', product: ClientConfigProductName.PORTAL })
export class BudgetCalculatorConfig extends LazyClientConfigBase {
    enableBudgetCaclculator: boolean;
}

@LazyClientConfig({ key: 'ptOtherStakeLimits', product: ClientConfigProductName.PORTAL })
export class OtherStakeLimitsConfig extends LazyClientConfigBase {
    v2Enabled: boolean;
}

@LazyClientConfig({ key: 'ptValuePerception', product: ClientConfigProductName.PORTAL })
export class ValuePerceptionClientConfig extends LazyClientConfigBase {
    valuePerceptionEnabled: boolean;
}

@LazyClientConfig({ key: 'ptLicenseAgreement', product: ClientConfigProductName.PORTAL })
export class LicenseAgreementClientConfig extends LazyClientConfigBase {
    isEnabled: boolean;
}

export class ServiceClosureConfigs {
    isEnabled: string;
    redirectTo: string;
}

@LazyClientConfig({ key: 'ptLoyalty', product: ClientConfigProductName.PORTAL })
export class LoyaltyConfig extends LazyClientConfigBase {
    enableMarriottBonvoy: boolean;
}
