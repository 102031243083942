import { Input, EventEmitter, Output, OnDestroy, Directive } from '@angular/core';
import { AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ValidationRuleSetTyped, DynamicValidationService, PortalFormGroup, PortalFormControl, ValidationFactories } from '@frontend/vanilla/shared/forms';
import { Subscription, from } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ValidationHelperService } from '../forms/validation-helper.service';
import { ModularFormContext } from './models/context-base';
import { DynamicFormComponentConfiguration } from './models/dynamic-form-component-configuration';

@Directive()
export abstract class ModularSubComponentBase implements OnDestroy {
    @Input() component: DynamicFormComponentConfiguration;
    @Input() context: ModularFormContext;
    @Input() parentForm: PortalFormGroup;
    validationHelper: ValidationHelperService;
    dynamicValidation: DynamicValidationService;
    @Output() afterViewInit = new EventEmitter();

    private revalidateDepMap = new Map<string, string[]>();
    private subscriptions: Subscription[] = [];

    get rootForm(): PortalFormGroup {
        return <PortalFormGroup>this.parentForm.root;
    }

    abstract initControls(): void;

    unsubscribeWhenDestroyed(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    afterFormInit(): void {
        this.revalidateDepMap.forEach((deps, controlName) => {
            deps.map((dep) => {
                const control = this.getControl(dep);
                if (control) {
                    // NOTE(mh): fix for distinctUntilChanged not defined in unit tests on EventEmitter (although operators from vanilla are there). Try to remove when @ngtools/webpack is updated
                    from(control.valueChanges)
                        .pipe(distinctUntilChanged())
                        .subscribe(() => this.getControl(controlName).updateValueAndValidity());
                }
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.map((s) => s.unsubscribe());
    }

    getControl(controlName: string, { onlyParentForm }: { onlyParentForm?: boolean } = { onlyParentForm: false }): AbstractControl {
        const formToSearch = onlyParentForm ? this.parentForm : this.rootForm;
        return formToSearch.getFlat(controlName)!;
    }

    addControl(
        controlName: string,
        options?: {
            validationOverrides?: ValidationRuleSetTyped | null;
            validationFactoryOverrides?: Partial<ValidationFactories>;
            additionalValidators?: ValidatorFn[];
            additionalAsyncValidators?: AsyncValidatorFn[];
            dynamicValidationDependency?: string;
            revalidateOnChangeOf?: string[];
            updateOn?: string;
        },
    ) {
        options = Object.assign({ validationOverrides: {}, additionalValidators: [], additionalAsyncValidators: [] }, options);
        const validators: ValidatorFn[] = this.validationHelper
            .createValidators(controlName, { overrides: options.validationOverrides, validationFactoryOverrides: options.validationFactoryOverrides })
            .concat(options.additionalValidators!);
        const abstractControlOptions = Object.assign(
            { validators, asyncValidators: options.additionalAsyncValidators },
            options.updateOn && { updateOn: options.updateOn },
        );
        const control = new PortalFormControl(null, abstractControlOptions as any, null);
        this.parentForm.addControl(controlName, control);

        if (options.revalidateOnChangeOf) {
            options.revalidateOnChangeOf.map((r) => {
                if (this.revalidateDepMap.has(controlName)) {
                    this.revalidateDepMap.get(controlName)!.push(r);
                } else {
                    this.revalidateDepMap.set(controlName, [r]);
                }
            });
        }

        if (options.dynamicValidationDependency) {
            this.dynamicValidation
                .register(
                    controlName,
                    from(this.rootForm.valueChanges).pipe(
                        map(() => this.rootForm.getValueFlat()[options!.dynamicValidationDependency!]),
                        distinctUntilChanged(),
                    ),
                )
                .subscribe((validation) => {
                    control.setValidators(
                        this.validationHelper
                            .createValidators(controlName, {
                                baseOverrides: validation.rules,
                                overrides: options!.validationOverrides,
                                validationFactoryOverrides: options!.validationFactoryOverrides,
                            })
                            .concat(options!.additionalValidators!),
                    );
                    control.errorMapping = validation.mappings as any;
                    control.updateValueAndValidity();
                });
        }

        if (this.onControlAdded) {
            this.onControlAdded(controlName, control);
        }
    }

    protected onControlAdded?(name: string, control: PortalFormControl): void;
}
