import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptMaxLength]',
})
export class MaximumLengthLimitDirective {
    @Input('ptMaxLength') maxLength: number;

    constructor(private control: NgControl) {}
    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent) {
        const element = event.target as HTMLInputElement;
        if (element.value.length >= this.maxLength) {
            this.control.control!.setValue(element.value.substring(0, this.maxLength));
            return true;
        } else {
            return false;
        }
    }
}
