import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DynamicHtmlDirective, runOnAppInit } from '@frontend/vanilla/core';

import { DataConverterService } from './data-converter.service';
import { GenericComponentBootstrapService } from './generic-component-bootstrap.service';
import { ModularSubComponentHostComponent } from './modular-sub-component-host.component';
import { ModularSubComponentRegistryService, ModularSubComponentRegistryServiceNew } from './modular-sub-component-registry.service';
import { FieldsetComponent } from './sub-components/fieldset.component';
import { RowComponent } from './sub-components/row.component';

const components = [ModularSubComponentHostComponent, FieldsetComponent, RowComponent];

@NgModule({
    imports: [CommonModule, DynamicHtmlDirective],
    declarations: [components],
    exports: [components],
})
export class ModularFormsModule {
    static forRoot(): ModuleWithProviders<ModularFormsModule> {
        return {
            ngModule: ModularFormsModule,
            providers: [
                DataConverterService,
                ModularSubComponentRegistryService,
                ModularSubComponentRegistryServiceNew,
                runOnAppInit(GenericComponentBootstrapService),
            ],
        };
    }
}
