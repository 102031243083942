import { Component, OnInit } from '@angular/core';

import { ModularSubComponentBase } from '../modular-sub-component-base';
import { GenericSubComponent } from '../modular-sub-component-registry.service';

@Component({
    selector: 'pt-row',
    templateUrl: 'row.component.html',
})
@GenericSubComponent('RowComponent')
export class RowComponent extends ModularSubComponentBase implements OnInit {
    layoutClass: string;
    enableThreeStepRegUIEnhancements: string;

    initControls() {}

    ngOnInit() {
        const layout = this.component.parameters!.layout;
        this.layoutClass = `form-group-${layout}`;
        this.enableThreeStepRegUIEnhancements = this.context?.config?.main?.enableThreeStepRegUIEnhancements;
    }
}
