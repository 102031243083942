<div [hidden]="!isVisible">
    <div class="tooltip">
        <div class="lh-tooltip-h">{{ commonMessages.MinReq }}</div>

        <ul class="list-unstyled txt-s m-0">
            @for (tooltip of tooltips | keyvalue; track $index) {
                <ng-container [ngTemplateOutlet]="tooltipTemplate" [ngTemplateOutletContext]="{ tooltip }" />
            }
        </ul>

        @if (infoTooltips?.length) {
            <ul class="list-unstyled txt-s info m-0">
                @for (tooltip of infoTooltips | keyvalue; track $index) {
                    <ng-container [ngTemplateOutlet]="tooltipTemplate" [ngTemplateOutletContext]="{ tooltip }" />
                }
            </ul>
        }

        <ng-template #tooltipTemplate let-tooltip="tooltip">
            <li class="d-flex align-items-center">
                <span
                    [ngClass]="{
                        'theme-check': tooltip.value.isValid === true,
                        'theme-ex': tooltip.value.isValid === false,
                        'theme-info-i': tooltip.value.isValid === null
                    }"
                    class="ui-icon ui-icon-size-sm icon"></span>
                <div [innerHtml]="tooltip.value.text | trustAsHtml" class="txt-xs d-inline m-0"></div>
            </li>
        </ng-template>
    </div>
</div>
