import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CommonMessages, WindowRef } from '@frontend/vanilla/core';
import { ValidationConfig } from '@frontend/vanilla/shared/forms';
import forOwn from 'lodash-es/forOwn';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pt-validation-summary-label',
    templateUrl: 'validation-summary-label.component.html',
})
export class ValidationSummaryLabelComponent implements OnInit, OnDestroy {
    @Input() content: any;
    @Input() form: any;

    firstFormError: any;
    valueChangesSub: Subscription;

    private window: Window;

    constructor(
        private validationConfig: ValidationConfig,
        private commonMessages: CommonMessages,
        windowRef: WindowRef,
    ) {
        this.window = windowRef.nativeWindow;
    }

    ngOnInit() {
        if (!this.content) {
            throw new Error('Missing "content" on <pt-validation-summary-label />.');
        }
        if (!this.form) {
            throw new Error('Missing "form" on <pt-validation-summary-label />.');
        }
        this.valueChangesSub = this.form.valueChanges.subscribe(() => {
            this.getFirstError();
        });
    }

    ngOnDestroy(): void {
        if (this.valueChangesSub) {
            this.valueChangesSub.unsubscribe();
        }
    }

    goToErrorField(nextError: FormError) {
        if (!nextError.name) {
            return;
        }
        this.window.scrollTo(0, nextError.scrollTo - 10);
    }

    private getFirstError() {
        if (!this.form || !this.form.controls) {
            this.firstFormError = null;
            return;
        }
        let firstElementError = null,
            currentFormError = null;
        for (const key in this.form.controls) {
            const control = this.form.controls[key];
            if (control.invalid && control.errors && Object.keys(control.errors).length > 0) {
                const elementError = this.getCurrentFirstErrorElement(this.getInputElement(key), firstElementError);
                if (firstElementError !== elementError) {
                    firstElementError = elementError;
                    let formError: FormError | null;
                    if (elementError) {
                        formError = this.getFormErrorFromInputKey(key, Object.keys(control.errors)[0].toLocaleLowerCase(), elementError.scrollTo);
                        currentFormError = formError ? formError : currentFormError;
                    }
                }
            }
        }
        this.firstFormError = currentFormError;
    }

    private getCurrentFirstErrorElement(inputElement: ErrorElement | null, currentFirstErrorElement: ErrorElement | null): ErrorElement | null {
        if (!inputElement) {
            return currentFirstErrorElement;
        }
        if (!currentFirstErrorElement) {
            return inputElement;
        } else {
            return this.elementIsFirstError(inputElement, currentFirstErrorElement) ? inputElement : currentFirstErrorElement;
        }
    }

    private getFormErrorFromInputKey(key: string, firstErrorKey: string, scrollTo: number): FormError | null {
        if (!this.content.formContent) {
            this.content.formContent = this.content.form;
        }
        const firstErrorFormMessages = this.content.formContent[key];
        if (firstErrorFormMessages) {
            const validationMessage = this.getValue(
                firstErrorFormMessages.validation,
                this.validationConfig.errorMapping[firstErrorKey] || firstErrorKey,
            );
            return new FormError(firstErrorFormMessages.label, key, validationMessage || this.commonMessages['GeneralValidationError'], scrollTo);
        }
        return null;
    }

    private getValue(theObject: any, theKey: string) {
        let value: any;
        forOwn(theObject, (v, k) => {
            if (!value && k.toLowerCase() === theKey.toLowerCase()) {
                value = v;
            }
        });
        return value;
    }

    private elementIsFirstError(inputElement: any, currentFirstErrorElement: any) {
        return (
            inputElement.column < currentFirstErrorElement['column'] ||
            (inputElement.column === currentFirstErrorElement['column'] && inputElement.offsetTop < currentFirstErrorElement['offsetTop'])
        );
    }

    private getInputElement(key: string): ErrorElement | null {
        const document = this.window.document;
        const inputElement = document.body.querySelectorAll('[name="' + key + '"]:not([type="hidden"])')[0];
        if (!inputElement) {
            return null;
        }
        const bodyRect = document.body.getBoundingClientRect();
        const inputElementRect = inputElement && inputElement.getBoundingClientRect();
        let inputColumn = 0;
        if (bodyRect && inputElementRect) {
            // break points: 600, 1024
            const columnWidth = bodyRect.width >= 1024 ? bodyRect.width / 3 : bodyRect.width >= 600 ? bodyRect.width / 2 : bodyRect.width;
            inputColumn = Math.ceil(inputElementRect.left / columnWidth);
        }
        const element = new ErrorElement(
            inputElementRect ? inputElementRect.top : 0,
            inputColumn ? inputColumn : 0,
            (inputElement as HTMLElement).offsetTop,
        );
        if (element.offsetTop !== 0) {
            return element;
        }
        return null;
    }
}

export class ErrorElement {
    constructor(
        public offsetTop: number,
        public column: number,
        public scrollTo: number,
    ) {}
}

export class FormError {
    constructor(
        public label: string,
        public name: string,
        public errorMessage: string,
        public scrollTo: number,
    ) {}
}
