import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Unicodeservice {
  private dataSubject: Subject<Event> = new Subject<Event>();
  data$ = this.dataSubject.asObservable();

  updateformfield(data: Event): void {
    this.dataSubject.next(data);
  }
}
