import { Directive, HostListener, Input, OnInit, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptFilterInputCharacters]',
})
export class FilterInputCharactersDirective implements OnInit {
    @Input() ptFilterInputCharactersRegex: string;
    private regex: RegExp;
    private control: NgControl;

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        this.control = this.injector.get(NgControl);
        const regexString = '[' + this.ptFilterInputCharactersRegex + ']';
        this.regex = new RegExp(regexString, 'g');
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        if (this.control.control) this.control.control.setValue(this.filterCharacters(value));
    }

    private filterCharacters(inputValue: string) {
        if (inputValue == null) {
            return null;
        }

        return (inputValue + '').replace(this.regex, '');
    }
}
