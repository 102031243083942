import { Component, Input, OnInit } from '@angular/core';
import { WindowRef, MessageQueueService, TrackingService } from '@frontend/vanilla/core';

import { ApiService } from '../api/api.service';

@Component({
    selector: 'pt-scroll-view',
    templateUrl: 'scroll-view.component.html',
})
export class ScrollViewComponent implements OnInit {
    @Input() endpoint: string;
    @Input() noItemsFoundMessage: string;
    @Input() itemsObj: any;
    @Input() pageSizeConfig: number;

    loadingData?: boolean | null = null;

    private delta: number = 500;
    private document: Document;
    private nextPage: number = 0;
    private pageSize: number;

    constructor(
        private api: ApiService,
        private windowRef: WindowRef,
        private messageQueue: MessageQueueService,
        private trackingService: TrackingService,
    ) {}

    ngOnInit() {
        this.windowRef.nativeWindow.addEventListener('scroll', () => this.onScroll());
        this.document = this.windowRef.nativeWindow.document;
        if (!this.itemsObj) {
            this.itemsObj = {};
        }
        this.pageSize = this.pageSizeConfig || 15;

        this.getItems(true, this.nextPage);
    }

    private getItems(resetPage: boolean, pageIndex: number) {
        if (this.loadingData) {
            // there is already a previous request
            return;
        }

        this.loadingData = true;
        const queryParams = {
            pageIndex: pageIndex,
            pageSize: this.pageSize,
        };

        this.api.get(this.endpoint, queryParams).subscribe(
            (data) => this.onGetItemsSuccess(data, resetPage),
            () => this.onGetItemsError(),
        );
    }

    private updateItems(resetPage: boolean) {
        if (resetPage) {
            this.nextPage = 0;
        }

        // stop condition, infinitive scroll (no more data)
        if (this.nextPage === -1) {
            return;
        }

        // clear previous messages
        this.messageQueue.clear({ clearPersistent: true });

        this.getItems(resetPage, this.nextPage);
    }

    private onScroll() {
        const scrollTop = this.document.documentElement.scrollTop || this.document.body.scrollTop;
        if (this.document.body.scrollHeight > 0) {
            if (scrollTop + this.document.body.offsetHeight >= this.document.body.scrollHeight - this.delta) {
                if (!this.loadingData) {
                    this.updateItems(false);
                }
            }
        }
    }

    private onGetItemsSuccess(data: any, resetPage: boolean) {
        if (data && data[this.endpoint]) {
            if (resetPage) {
                this.itemsObj.items = [];
            }
            this.itemsObj.items.push.apply(this.itemsObj.items, data[this.endpoint]);
            this.nextPage = data.nextPage;
        } else {
            this.itemsObj.items = [];
        }
        this.loadingData = false;
        if (this.endpoint == 'loginhistory') {
            if (this.itemsObj.items.length) {
                this.trackingService.triggerEvent('Event.LoginHistoryLoad', {
                    'component.CategoryEvent': 'Login_History',
                    'component.LabelEvent': 'Last30Days_RegLoad_' + this.itemsObj.items.length,
                    'component.ActionEvent': 'Default',
                    'component.PositionEvent': 'not applicable',
                    'component.LocationEvent': 'not applicable',
                });
            } else if (!this.itemsObj.items.length) {
                this.trackingService.triggerEvent('Event.LoginHistoryLoad', {
                    'component.CategoryEvent': 'Login_History',
                    'component.LabelEvent': 'Last30Days_RegLoad_0',
                    'component.ActionEvent': 'Default',
                    'component.PositionEvent': 'not applicable',
                    'component.LocationEvent': 'not applicable',
                });
            }
        }
    }

    private onGetItemsError() {
        this.itemsObj.items = [];
        if (this.endpoint == 'loginhistory') {
            this.trackingService.triggerEvent('Event.LoginHistoryLoad', {
                'component.CategoryEvent': 'Login_History',
                'component.LabelEvent': 'Last30Days_RegLoad_0',
                'component.ActionEvent': 'Default',
                'component.PositionEvent': 'not applicable',
                'component.LocationEvent': 'not applicable',
            });
        }
        this.loadingData = false;
    }
}
