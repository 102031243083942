import { Component, OnInit, Input } from '@angular/core';

import { CircularProgressModel } from './circular-progress.model';

@Component({
    selector: 'pt-circular-progress',
    templateUrl: 'circular-progress.component.html',
})
export class CircularProgressComponent implements OnInit {
    @Input() colorStatusObj: CircularProgressModel;
    @Input() leftGraceDays: number;
    @Input() textTop: string;
    @Input() textBottom: string;
    @Input() overriddenStyleClass: string | null;
    @Input() overRideTopStyleOrNot: boolean = false;
    totalGraceDays: any;
    graceDaysPercentage: any;
    constructor() {}
    ngOnInit() {
        this.totalGraceDays = Math.max(...this.colorStatusObj.statusDurations);
        this.calculatePercentageUK();
    }

    calculatePercentageUK() {
        let percentage = parseInt(((this.leftGraceDays / this.totalGraceDays) * 100).toFixed(1));
        if (this.colorStatusObj.fakeStep) {
            if (Math.floor(percentage) >= 100) {
                const previousStepPercentage = ((this.leftGraceDays - 1) / this.totalGraceDays) * 100;
                percentage = parseInt((percentage - (100 - previousStepPercentage) / 2).toFixed(1));
            }
        }
        this.graceDaysPercentage = 100 - percentage + ', 100';
    }
}
