import { AbstractControl, ValidationErrors } from '@angular/forms';

import { PortalFormGroup, Validators } from '@frontend/vanilla/shared/forms';
import isNumber from 'lodash-es/isNumber';

import { PersonalInformation } from '../models/models';

/**
 * @whatItDoes Provides custom angular validators
 *
 * @stable
 */
// @dynamic
export class ValidatorsExtended extends Validators {
    static newDateNotUsedInPassword(
        field: string,
        formats: string[],
        dateFormatter: (date: any, format: string) => string | null,
        charOnEmptySpace: string = '',
    ) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!formats || !formats.length || isEmptyInputValue(control.value)) {
                return null;
            }
            if (!checkIfValidDate(control.value, charOnEmptySpace)) {
                return null;
            }
            const otherFieldValue = getOtherFieldValue(control, field);

            if (!otherFieldValue) {
                return null;
            }

            const controlValue: Date | string = control.value;

            if (stringContainsDate(otherFieldValue, controlValue, formats, dateFormatter)) {
                return { notUsedInOtherField: true };
            }

            return null;
        };
    }

    static validPassword(
        dateFormats: string[],
        dateFormatter: (date: any, format: string) => string | null,
        personalInformation?: PersonalInformation,
        charOnEmptySpace: string | null = null,
    ) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            personalInformation = personalInformation || {};

            let email: string = getOtherFieldValue(control, 'emailaddress') || personalInformation.email;
            if (email && email.includes('@')) {
                email = email.substring(0, email.indexOf('@'));
            }
            return validatePassword(
                control.value,
                [
                    getOtherFieldValue(control, 'firstname') || personalInformation.firstName,
                    getOtherFieldValue(control, 'lastname') || personalInformation.lastName,
                    getOtherFieldValue(control, 'secondlastname') || personalInformation.secondLastName,
                    email,
                    getOtherFieldValue(control, 'username') || personalInformation.username,
                ],
                [
                    getOtherFieldValue(control, 'dateofbirth') || personalInformation.dateOfBirth,
                    getNewDateOfBirthValue(control, 'dateofbirthnew', charOnEmptySpace) || personalInformation.dateOfBirth,
                ],
                dateFormats,
                dateFormatter,
            );
        };
    }

    static dateNotUsedInPassword(field: string, formats: string[], dateFormatter: (date: any, format: string) => string | null) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!formats || !formats.length || isEmptyInputValue(control.value)) {
                return null;
            }

            const otherFieldValue = getOtherFieldValue(control, field);

            if (!otherFieldValue) {
                return null;
            }

            const controlValue: Date | string = control.value;

            if (stringContainsDate(otherFieldValue, controlValue, formats, dateFormatter)) {
                return { notUsedInOtherField: true };
            }

            return null;
        };
    }

    static integer(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        let n: number | null = null;

        if (typeof control.value === 'string') {
            if (/^-?\d+$/.test(control.value)) {
                n = parseFloat(control.value);
            }
        } else {
            n = control.value;
        }

        return Number.isInteger(n as number) ? null : { number: { type: 'integer' } };
    }

    static validRemainingBalanceLimit() {
        return (control: AbstractControl): ValidationErrors | null | undefined => {
            const fromAccountBalance = control.root.get('fromAccountBalance') || control.root.get('accountLimits')!.get('fromAccountBalance');
            if (fromAccountBalance) {
                if (!fromAccountBalance.value) {
                    return null;
                }
                return (!isNaN(Number(control.value)) ? Number(control.value) : control.value) >=
                    (!isNaN(Number(fromAccountBalance.value)) ? Number(fromAccountBalance.value) : fromAccountBalance.value)
                    ? { max: true }
                    : null;
            }
            return;
        };
    }

    static validateNie() {
        return (control: AbstractControl): ValidationErrors | null => {
            const nie = control.value;
            if (!nie) {
                return Validators.nullValidator;
            }
            const startLetter = nie.charAt(0).toUpperCase();
            const numbers = nie.charAt(1) === '0' && nie.length === 10 ? nie.substring(2, 9) : nie.substring(1, 8);
            const letter = nie.charAt(1) === '0' && nie.length === 10 ? nie.charAt(9).toUpperCase() : nie.charAt(8).toUpperCase();

            // transform XYZ to number and then validate as NIF
            let num = '';
            switch (startLetter) {
                case 'X':
                    num = '0';
                    break;
                case 'Y':
                    num = '1';
                    break;
                case 'Z':
                    num = '2';
                    break;
            }

            const nieNumbers = parseInt(num + numbers, 10);
            return checkSum(nieNumbers) === letter ? Validators.nullValidator : { nienif: true };
        };
    }

    static validateNif() {
        return (control: AbstractControl): ValidationErrors | null => {
            const nif = control.value;
            if (!nif) {
                return Validators.nullValidator;
            }
            // split DNI to numbers (first 8) and checksum letter (last position)
            const numbers = parseInt(nif.substring(0, 8), 10);
            const letter = nif.charAt(nif.length - 1).toUpperCase();

            // assert that the letter is the same as calculated by the checksum
            return letter === checkSum(numbers) ? Validators.nullValidator : { nienif: true };
        };
    }

    static validCnp(
        improvedValidation?: boolean,
        skipGenderCheck?: boolean,
        dobProvider?: (c: AbstractControl) => { day: string; month: string; year: string },
        genderProvider?: (c: AbstractControl) => string,
    ) {
        const skip = skipGenderCheck || false;

        let getDob: any = dobProvider;
        let getGender = genderProvider;

        if (!getDob) {
            //assign default dob value provider
            getDob = (c: any) => {
                const dob = getOtherField(c, 'dateofbirth');
                if (dob && dob.valid) {
                    const dobDate = new Date(dob.value);
                    return {
                        day: dobDate.getDate().toFixed(0),
                        month: (dobDate.getMonth() + 1).toFixed(0),
                        year: dobDate.getFullYear().toFixed(0),
                    };
                }
                return null;
            };
        }

        if (!getGender) {
            //assign default gender value provider
            getGender = (c) => {
                const gender = getOtherField(c, 'gender');
                return gender && gender.valid ? gender.value : null;
            };
        }

        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }
            if (!improvedValidation) return validateCnp(control.value, getDob(control), getGender!(control), skip) ? null : { cnp: { valid: false } };
            else return validateImprovedCnp(control.value, getDob(control), getGender!(control), skip) ? null : { cnp: { valid: false } };
        };
    }

    static float(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        let n: number | null = null;

        if (typeof control.value === 'string') {
            if (/^-?\d+\.?\d*$/.test(control.value)) {
                n = parseFloat(control.value);
            }
        } else {
            n = control.value;
        }

        return isNumber(n as any) ? null : { number: { type: 'float' } };
    }

    static notUsedInOtherField(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            const otherFieldValue = getOtherFieldValue(control, field);

            if (!otherFieldValue) {
                return null;
            }

            const controlValue: string = control.value.toLowerCase();
            const otherFieldValueLower: string = otherFieldValue.toLowerCase();

            if (controlValue.includes(otherFieldValueLower) || otherFieldValueLower.includes(controlValue)) {
                return { notUsedInOtherField: true };
            } else {
                return null;
            }
        };
    }

    static notUsedInOtherFieldExactAddress1(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };

            const otherFieldValue = getOtherFieldValue(control, field);

            if (!otherFieldValue) return null;

            const controlValue: string = control.value.toLowerCase();
            const otherFieldValueLower: string = otherFieldValue.toLowerCase();

            if (controlValue === otherFieldValueLower || otherFieldValueLower === controlValue) {
                return { UsedInOtherFieldExactAddress: true };
            } else return null;
        };
    }

    static notUsedInOtherFieldExactCity(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            const otherFieldValue = getOtherFieldValue(control, field);
            if (isEmptyInputValue(control.value)) return { Required: true };
            if (!otherFieldValue) return null;
            const controlValue: string = control.value.toLowerCase();
            const otherFieldValueLower: string = otherFieldValue.toLowerCase();
            if (controlValue === otherFieldValueLower || otherFieldValueLower === controlValue) {
                return { UsedInOtherFieldExactCity: true };
            } else return null;
        };
    }

    static doNotAcceptOnlyNumbers(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };
            const value = getOtherFieldValue(control, field);
            let spaces: any;
            for (let i = 0; i < 100; i++) spaces = ' ' + spaces;
            if (spaces.includes(value)) return null;
            const numberSet = '0123456789 ';
            for (let i = 0; i < value.length; i++) {
                if (!numberSet.includes(value[i])) return null;
            }
            return { NumbersOnly: true };
        };
    }

    static doNotAcceptNumbers() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };
            const str = control.value;
            for (let i = str.length - 1; i >= 0; i--) {
                const d = str.charCodeAt(i);
                if (d >= 48 && d <= 57) return { NumberNotAllowed: true };
            }
            return null;
        };
    }

    static doNotCountSpaceName(field: string, minCount: number | undefined) {
        return (control: AbstractControl): ValidationErrors | null => {
            let value = getOtherFieldValue(control, field);
            if (value != null) {
                value = value.replace(/\s/g, '');
                const count = value.length;
                if (minCount) {
                    if (count < minCount) return { InvalidNameLength: true };
                }
            }
            return null;
        };
    }

    static doNotCountSpaceAddress(field: string, minCount: number | undefined) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };
            let value = getOtherFieldValue(control, field);
            if (value != null) {
                value = value.replace(/\s/g, '');
                const count = value.length;
                if (minCount) {
                    if (count < minCount) return { InvalidAddressLength: true };
                }
            }
            return null;
        };
    }

    static doNotAcceptDifferentFirstLetter(field: string, letter: string | undefined) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };
            const value = getOtherFieldValue(control, field);
            if (value != null && value.toLowerCase().charAt(0) != letter!.toLowerCase()) {
                return { FirstLetterValidationFailed: true };
            }
            return null;
        };
    }

    static doNotAcceptSameLetterThroughout(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = getOtherFieldValue(control, field);
            if (value === null) return null;
            const spaces: any = '                              ';
            if (spaces.includes(value)) return null;
            const word = value.toUpperCase();
            let flag = false;
            for (let i = 0; i <= 4; i++) {
                if (word[i] != word[0]) flag = true;
            }
            if (flag) return null;
            else return { SameLetterName: true };
        };
    }

    static checkSpaces(field: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) return { Required: true };
            const value = getOtherFieldValue(control, field);
            if (value === null) return null;
            const spaces: any = '                              ';
            if (spaces.includes(value)) return { StringLength: true };
            else return null;
        };
    }

    static doHouseNumberValidation(rules: any | undefined) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (rules.required && isEmptyInputValue(control.value)) return { Required: true };
            if ((rules.maxLength && control?.value?.length > rules.maxLength) || (rules.minLength && control?.value?.length < rules.minLength))
                return { StringLength: true };
            let isValid = true;
            const regex = rules?.regex;
            if (control.value && regex) {
                if (!new RegExp(regex).test(control.value) || /[a-zA-Z]{2,}/.test(control.value) || /[ -/]{2,}/.test(control.value))
                    return { InvalidHouseNumber: true };
                if (control.value.indexOf('/') > -1) {
                    isValid = validateHouseNumString(control.value.split('/'));
                    if (isValid) isValid = control.value.split('/').every((x: any) => validateHouseNumString(x.split('-')));
                } else if (control.value.indexOf('-') > -1) {
                    isValid = validateHouseNumString(control.value.split('-'));
                }
            }
            return isValid ? null : { InvalidHouseNumber: true };
        };
    }
    static doNotAllowTitles(titles: string[]) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }
            const controlValue: string = control.value.toLowerCase();
            const inputtitle = controlValue.split(' ');
            if (titles.includes(inputtitle[0])) {
                return { notAllowedTitles: true };
            }
            return null;
        };
    }

    static validPeselNumber(
        dobProvider?: (c: AbstractControl) => { day: string; month: string; year: string },
        genderProvider?: (c: AbstractControl) => string,
    ) {
        let getDob: any = dobProvider;
        let getGender = genderProvider;

        if (!getDob) {
            //assign default dob value provider
            getDob = (c: any) => {
                const dob = getOtherField(c, 'dateofbirth');
                if (dob && dob.valid) {
                    const dobDate = new Date(dob.value);
                    return {
                        day: dobDate.getDate().toFixed(0),
                        month: (dobDate.getMonth() + 1).toFixed(0),
                        year: dobDate.getFullYear().toFixed(0),
                    };
                }
                return null;
            };
        }

        if (!getGender) {
            //assign default gender value provider
            getGender = (c) => {
                const gender = getOtherField(c, 'gender');
                return gender && gender.valid ? gender.value : null;
            };
        }

        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value)
                if (control.value.length === 11) {
                    if (!validatePeselNumber(control.value, getGender!(control))) return { peselNumberInvalid: true };
                    if (!validatePeselNumberDOB(control.value, getDob(control))) return { dobPeselMisMatch: true };
                }
            return null;
        };
    }
}

function validateHouseNumString(value: string[]): boolean {
    let isValid = true;
    if (value) {
        const alphabetPattern = /[a-zA-Z]/;
        value.some((element: any, index: any, array: any) => {
            if (index + 1 < array.length) {
                if (!array[index + 1]) {
                    isValid = false;
                    return;
                } else {
                    isValid = alphabetPattern.test(array[index + 1].charAt(0))
                        ? alphabetPattern.test(array[index].charAt(array[index].length - 1))
                            ? (array[index + 1].length == 1 && alphabetPattern.test(array[index + 1].charAt(0))) ||
                              (array[index + 1].length > 1 && /[1-9]/.test(array[index + 1].charAt(0)))
                                ? true
                                : false
                            : false
                        : true;
                    // i) False, if first character after seperator is Alphabet and first character to left side of seperator is Number
                    //ii) True, if subsequent string after seperator is either 'single alphabet or number or number followed by alphanumeric'
                    if (!isValid) return;
                }
            }
        });
    }
    return isValid;
}

function validatePassword(
    password: string,
    stringValues: string[],
    dateValues: any[],
    dateFormats: string[],
    dateFormatter: (date: any, format: string) => string | null,
) {
    password = password.toLowerCase();
    if (
        stringValues
            .filter((s) => s)
            .map((s) => s.toLowerCase())
            .some((s) => password.includes(s) || s.includes(password)) ||
        dateValues.some((d) => stringContainsDate(password, d, dateFormats, dateFormatter))
    ) {
        return { validPassword: true };
    }

    return null;
}

function checkIfValidDate(date: string, charOnEmptySpace: string | null) {
    if (typeof date === 'string') {
        const dateArray = date.split('/');
        const day = dateArray[0];
        const month = dateArray[1];
        const year = dateArray[2];
        if (charOnEmptySpace)
            if (
                day &&
                month &&
                year &&
                parseInt(day) !== 0 &&
                parseInt(month) !== 0 &&
                parseInt(year) !== 0 &&
                day.indexOf(charOnEmptySpace) === -1 &&
                month.indexOf(charOnEmptySpace) === -1 &&
                year.indexOf(charOnEmptySpace) === -1
            )
                return true;
    }
    return false;
}

function getOtherFieldValue(control: AbstractControl, field: string) {
    const otherField = getOtherField(control, field);
    return otherField ? otherField.value : null;
}

function getNewDateOfBirthValue(control: AbstractControl, field: string, charOnEmptySpace: string | null) {
    const otherField = getOtherField(control, field);
    return otherField && checkIfValidDate(otherField.value, charOnEmptySpace) ? otherField.value : null;
}
function stringContainsDate(value: string, date: any, formats: string[], dateFormatter: (date: any, format: string) => string | null) {
    if (!formats || !date) {
        return false;
    }

    const shortMonth = dateFormatter(date, 'MMM');
    const shortMonthWithoutDot = shortMonth!.length <= 3 ? shortMonth : shortMonth!.substring(0, 3);

    if (shortMonth && shortMonthWithoutDot)
        if (formats.some((f) => value.toLowerCase().includes(dateFormatter(date, f)!.replace(shortMonth, shortMonthWithoutDot).toLowerCase()))) {
            return true;
        }

    return false;
}

function getOtherField(control: AbstractControl, field: string) {
    const root = control.root;
    let otherField: AbstractControl | null;
    if (root instanceof PortalFormGroup) {
        otherField = root.getFlat(field);
    } else {
        otherField = root.get(field);
    }

    if (!otherField) {
        return null;
    }

    return otherField;
}
function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

function checkSum(num: number) {
    const checkSumLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    return checkSumLetters.charAt(num % 23);
}
function getControlSum(cnp: any) {
    let k: number = 0;
    const CONTROL_VALUES: number[] = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    for (let i = 0; i < 12; i++) {
        k += CONTROL_VALUES[i] * cnp[i];
    }
    k %= 11;
    if (k == 10) {
        k = 1;
    }
    return k;
}
function validateBirthdate(cnp: string, dateOfBirth: any) {
    /** Returns if the given string represents a valid CNP for the given birthdate.
     * The 2nd and the 3rd digits represent the last two digits from the year birthdate,
     * the 4th and 5th represent the month and the 7th and 8th the day.
     */
    const day = pad(dateOfBirth.day, 2);
    const month = pad(dateOfBirth.month, 2);
    const year = dateOfBirth.year.substring(2);
    return cnp.length > 6 && cnp.substring(1, 7) == year + month + day;
}
function validateGender(cnp: string, gender: string, dateOfBirth: any) {
    const male = gender === 'Mr' || gender === 'Male' ? true : false;
    const g1 = Number(cnp.substring(0, 1));
    const g2 = dateOfBirth.year < 2000 ? (male ? 1 : 2) : male ? 5 : 6;
    //Handle Foreign Residents
    if (g1 != g2) return g1 == (male ? 7 : 8);
    return g1 == g2;
}
function validateCnp(value: string, dateOfBirth: { day: string; month: string; year: string }, gender: string, skipGenderCheck: boolean) {
    // The CNP consist of 13 digits as follows:
    // 1 digit for sex and century of birth: 1, 3, and 5 = male; 2, 4, and 6 = female.
    // 1 and 2 = 20th century, 3 and 4 = 19th century, and 5 and 6 = 21st century.
    // (For legal residents the numbers 7 and 8 are used to encode the sex of the person having the residence permit);
    // 6 digits for birth date in the format YYMMDD
    // 2 digits for place of birth (the code for the county) (For legal residents the numbers encode the region where the person having the residence permit comes from);
    // 3 other digits
    // 1 control digit

    if (!value || !gender || !dateOfBirth || !dateOfBirth.year || !dateOfBirth.month || !dateOfBirth.day) {
        return true; // if values are not defined, validate as success
    }

    if (value.length !== 13) {
        return false;
    }

    const day = pad(dateOfBirth.day, 2);
    const month = pad(dateOfBirth.month, 2);
    const year = dateOfBirth.year.substring(2);
    const century = dateOfBirth.year.substring(0, 2);

    if (!skipGenderCheck) {
        const g = gender === 'Mr' || gender === 'Male' ? 'm' : 'f';
        const fd = value.substring(0, 1);

        const fdmap: { [key: string]: string } = { m19: '1', f19: '2', m18: '3', f18: '4', m20: '5', f20: '6' };
        const fde = fdmap[g + century];
        const fder = g === 'm' ? '7' : g === 'f' ? '8' : '';
        if (fd !== fde && fd !== fder) {
            return false;
        }
    }

    if (value.length < 7 || value.substring(1, 7) !== year + month + day) {
        return false;
    }
    return true;
}
function validateImprovedCnp(value: string, dateOfBirth: { day: string; month: string; year: string }, gender: string, skipGenderCheck: boolean) {
    const YEAR_OFFSET = [0, 1900, 1900, 1800, 1800, 2000, 2000];

    if (value.length !== 13 || isNaN(Number(value))) {
        return false;
    }
    const cnpDigits: number[] = Array.from(String(value), Number);
    if (cnpDigits == null || cnpDigits[12] != getControlSum(cnpDigits)) {
        return false;
    }
    const day = cnpDigits[5] * 10 + cnpDigits[6];
    if (day < 1) {
        return false;
    }
    const month = cnpDigits[3] * 10 + cnpDigits[4];
    if (month < 1 && month > 12) {
        return false;
    }
    const year = YEAR_OFFSET[cnpDigits[0]] + cnpDigits[1] * 10 + cnpDigits[2];
    const maxDayOfMonth = new Date(year, month, 0).getDate();

    if (day > maxDayOfMonth || !validateBirthdate(value, dateOfBirth)) {
        return false;
    }
    if (!skipGenderCheck) {
        return validateGender(value, gender, dateOfBirth);
    }
    return true;
}

function pad(value: number | string, length: number, delimiter = '0') {
    const result = value + '';
    return result.length >= length ? result : new Array(length - result.length + 1).join(delimiter) + result;
}

function validatePeselNumberDOB(pesel: string, dateOfBirth: { day: string; month: string; year: string }): boolean {
    let match = true;
    if (dateOfBirth) {
        if (!isValidFirstSixPeselNumber(pesel, dateOfBirth)) match = false;
    }
    return match;
}

function validatePeselNumber(pesel: string, gender: string): boolean {
    if (pesel === null || pesel.length !== 11) return false;
    // check first 6 digits should be in YYMMDD format
    if (gender != null || !gender) {
        const genderCheck = pesel.substring(9, 10);
        const malePattern = '13579';
        const femalePattern = '02468';
        // check for 10th digit -- X represents the gender (even number for female; and odd number for male)
        if (gender.match('Male')) {
            if (!malePattern.match(genderCheck)) return false;
        } else if (gender.match('Female')) {
            if (!femalePattern.match(genderCheck)) return false;
        }
    }

    const cDigit = pesel.substring(10, 11);
    const checkDigit = generateValidCheckDigitForPesel(pesel);
    if (checkDigit != parseInt(cDigit)) {
        return false;
    }
    const allzeroiesRegex = /^0+$/;
    if (allzeroiesRegex.test(pesel)) return false;
    const arr = pesel.split('');
    let sum: number = 0;

    for (let i: number = 0; i < arr.length - 1; i++) {
        sum += +arr[i] * getMultiplier(i + 1);
    }

    const modulo = sum % 10;
    const lastD = Number(pesel.substr(pesel.length - 1));

    return (modulo === 0 && lastD === 0) || lastD === 10 - modulo;
}

/**
 * Q is a check digit. This digit will be 10 - A, where A is the remainder after dividing B with 10, where B is (D1
 * * 1) + (D2 * 3) + (D3 * 7) + (D4 * 9) + (D5 * 1) + (D6 * 3) + (D7 * 7) + (D8 * 9) + (D9 * 1) + (D10 * 3)
 **/
function generateValidCheckDigitForPesel(peselNumber: string) {
    const first10Digits = peselNumber.substring(0, 10);

    // Long number = Long.parseLong(first10Digits);
    let i = 0;
    let j = 1;
    let checkDigit = 0;
    while (i < first10Digits.length) {
        if (j == 5) {
            j = j + 2;
        }
        if (j > 9) {
            j = 1;
        }
        const value = parseInt(first10Digits.substring(i, ++i));
        checkDigit = checkDigit + value * j;
        j = j + 2;
    }

    checkDigit = checkDigit % 10;
    if (checkDigit != 0) {
        return 10 - checkDigit;
    } else {
        return 0;
    }
}

function isValidFirstSixPeselNumber(peselNumber: string, dateOfBirth: { day: string; month: string; year: string }) {
    let result = true;
    try {
        const day: number = parseInt(dateOfBirth.day);
        const wholeYear: number = parseInt(dateOfBirth.year);
        const Year = dateOfBirth.year;
        const twoDigityear: number = parseInt(Year.substring(2, 4));
        let month: number = parseInt(dateOfBirth.month);
        const yearPeselNumber: number = parseInt(peselNumber.substring(0, 2));
        const monthPeselNumber: number = parseInt(peselNumber.substring(2, 4));
        const datePeselNumber: number = parseInt(peselNumber.substring(4, 6));

        if (wholeYear >= 2000) {
            month = month + 20;
        }

        if (yearPeselNumber != twoDigityear || monthPeselNumber != month || datePeselNumber != day) {
            result = false;
        }
    } catch (e) {
        result = false;
    }
    return result;
}

function getMultiplier(index: number): number {
    switch (index % 4) {
        case 1:
            return 1;
        case 2:
            return 3;
        case 3:
            return 7;
        case 0:
            return 9;
    }
    throw 'Something went wrong with the index calculation';
}
