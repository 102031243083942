import { Component, OnInit } from '@angular/core';
import { Logger } from '@frontend/vanilla/core';
import get from 'lodash-es/get';

import { ModularSubComponentBase } from '../modular-sub-component-base';
import { GenericSubComponent } from '../modular-sub-component-registry.service';

@Component({
    selector: 'pt-fieldset',
    templateUrl: './fieldset.component.html',
})
@GenericSubComponent('FieldsetComponent')
export class FieldsetComponent extends ModularSubComponentBase implements OnInit {
    title: string;

    constructor(private logger: Logger) {
        super();
    }

    ngOnInit() {
        if (!this.component.parameters || !this.component.parameters.legendPath) {
            return;
        }
        const path = this.component.parameters.legendPath;
        this.title = get(this.context, path);

        if (!this.title || typeof this.title !== 'string') {
            this.logger.warn(`${this.component.name}: Path ${path} does not map to any string. Actual value ${this.title}, context: ${this.context}`);
        }
    }

    initControls(): void {}
}
