export class TrackingEvent {
    CategoryEvent?: string;
    LabelEvent?: string;
    ActionEvent?: string;
    PositionEvent?: string;
    LocationEvent?: string;
    EventDetails?: string;
    UrlClicked?: string;
    PageName?: string;
    SiteSection?: string;
    RefferingAction?: string;
    Value?: number | string | null;
    RegistrationType?: string | null;
    Product?: string;
    HccCategory?: string | null;
    HccArticle?: string | null;
    AffordabilityJourney?: string | null;
    ContentPosition?: number | string | null;
    RegistrationSourcePath?: string | null;
    Progress?: string;
    KycVerifcationProcess?: string;
    LimitDetails?: string;
    UserAccountId?: string | null;
    offerId?: string | null;
    type?: string | null;
    flowName?: string | null;
    AffordabilityGroups?: string | null;
    AffLimitType?: string | null;
    AffAgeGroups?: string | null;
}

export const EventNameConst = {
    HelpAndContact: 'Event.HCC',
    Tracking: 'Event.Tracking',
    Value: 'Event.Value',
    ContentView: 'contentView',
};

export class ErrorDetail {
    [key: string]: string | number | undefined;
}
