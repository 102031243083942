import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, ViewContainerRef } from '@angular/core';
import { Logger } from '@frontend/vanilla/core';
import { DynamicValidationService, PortalFormGroup } from '@frontend/vanilla/shared/forms';
import { Subscription } from 'rxjs';

import { ValidationHelperService } from '../forms/validation-helper.service';
import { ModularFormContext } from './models/context-base';
import { DynamicFormComponentConfiguration } from './models/dynamic-form-component-configuration';
import { ModularSubComponentRegistryService, ModularSubComponentRegistryServiceNew } from './modular-sub-component-registry.service';

@Component({
    selector: 'pt-modular-sub-component-host',
    template: '',
})
export class ModularSubComponentHostComponent implements OnInit, OnChanges, OnDestroy {
    @Input() component: DynamicFormComponentConfiguration;
    @Input() parentForm: PortalFormGroup;
    @Input() context: ModularFormContext;
    @Input() listenToComponentChanges: boolean;
    @Output() afterViewInit = new EventEmitter();

    private formInitSubscription: Subscription;

    constructor(
        private registrationRegistry: ModularSubComponentRegistryService,
        private registrationRegistryNew: ModularSubComponentRegistryServiceNew,
        private logger: Logger,
        private validationHelper: ValidationHelperService,
        private dynamicValidation: DynamicValidationService,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.loadComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            this.listenToComponentChanges &&
            changes['component'] &&
            !changes['component'].isFirstChange() &&
            changes['component'].previousValue !== changes['component'].currentValue
        ) {
            this.loadComponent();
        }
    }

    loadComponent() {
        const componentType = this.registrationRegistry.get(this.context.prefix, this.component.type);
        const alternateComponentType = this.registrationRegistryNew.get(this.context.prefix, this.component.type);
        if (!componentType) {
            if (!componentType && !alternateComponentType) {
                this.logger.warn(`Cannot find the component type '${this.component.type}' in the registry.`);
                return;
            }
        }

        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent(componentType ? componentType : alternateComponentType!);
        const newSubComponent = componentRef.instance;
        newSubComponent.context = this.context;
        newSubComponent.parentForm = this.parentForm;
        newSubComponent.component = this.component;
        newSubComponent.validationHelper = this.validationHelper;
        newSubComponent.dynamicValidation = this.dynamicValidation;
        if (this.context.bootstrapSubComponent) {
            this.context.bootstrapSubComponent(newSubComponent);
        }
        newSubComponent.initControls();

        this.formInitSubscription = this.context.events.formInitDone.subscribe(() => {
            if (newSubComponent.afterFormInit) {
                newSubComponent.afterFormInit();
            }
        });

        // relay the dynamic component's afterViewInit event
        newSubComponent.afterViewInit.subscribe((event: any) => {
            this.afterViewInit.emit(event);
        });
    }

    ngOnDestroy() {
        if (this.formInitSubscription) {
            this.formInitSubscription.unsubscribe();
        }
    }
}
