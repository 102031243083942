import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptPreventOnlySpace]',
})
export class PreventOnlySpaceDirective {
    constructor(private control: NgControl) {}

    @HostListener('blur', ['$event.target.value']) onInput(value: string) {
        if (this.control.control) this.control.control.setValue(value.trim());
    }
}
