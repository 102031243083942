import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class ApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.createForProduct(ApiService, {
        product: ClientConfigProductName.PORTAL,
        area: 'mobileportal',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.PORTAL).isEnabled,
    });
}
