export class DomicileAddressConstant {
    public static readonly DOMICILE_AS_RESIDENCE_ADDRESS: string = 'domicileasresidenceaddress';
    public static readonly DOMICILE_ADDRESS_CITY: string = 'domicileaddresscity';
    public static readonly DOMICILE_ADDRESS_FINDER: string = 'domicileaddressfinder';
    public static readonly DOMICILE_ADDRESS_LINE1: string = 'domicileaddress';
    public static readonly DOMICILE_ADDRESS_LINE2: string = 'domicileaddress2';
    public static readonly DOMICILE_ADDRESS_STATE: string = 'domicileaddressstate';
    public static readonly DOMICILE_ADDRESS_ZIP: string = 'domicileaddresszip';
    public static readonly DOMICILE_ADDRESS_COUNTRY_CODE: string = 'domicileaddresscountry';
}

export class SocialRegistrationFieldConstant {
    public static readonly FIRSTNAME: string = 'firstname';
    public static readonly FIRST_NAME: string = 'firstname';
    public static readonly LAST_NAME: string = 'lastname';
    public static readonly LASTNAME: string = 'lastname';
    public static readonly CITY: string = 'addresscity';
    public static readonly PHONENUMBER: string = 'mobilenumber';
    public static readonly ADDRESS: string = 'addressline1';
    public static readonly ADDRESS1: string = 'addressline1';
    public static readonly ADDRESS2: string = 'addressline2';
    public static readonly STATE: string = 'addressstate';
    public static readonly ZIP: string = 'addresszip';
    public static readonly COUNTRY: string = 'addresscountrycode';
    public static readonly EMAIL: string = 'emailaddress';
    public static readonly DATEOFBIRTH: string = 'dateofbirth';
    public static readonly DOB: string = 'dateofbirth';
    public static readonly BIRTH_CITY: string = 'birthcity';
    public static readonly NATIONALITY: string = 'nationality';
    public static readonly SOURCE_ACCOUNT_NAME: string = 'DE_VRFN_SOURCE_ACCOUNT';
    public static readonly CURRENCY: string = 'currencycode';
    public static readonly GENDER: string = 'gender';
    public static readonly MOBILE_COUNTRY_CODE: string = 'mobilecountrycode';
    public static readonly MOBILE_NUMBER: string = 'mobilenumber';
    public static readonly MAIDEN_NAME: string = 'maidenname';
}
export class ScanIdRegistrationFieldConstant {
    public static readonly EMAILADDRESS: string = 'emailaddress';
    public static readonly DATEOFBIRTH: string = 'dateofbirth';
    public static readonly GENDER: string = 'gender';
    public static readonly FIRSTNAME: string = 'firstname';
    public static readonly LASTNAME: string = 'lastname';
    public static readonly ADDRESS1: string = 'addressline1';
    public static readonly CITY: string = 'addresscity';
    public static readonly STATEPROVINCE: string = 'addressstate';
    public static readonly POSTALCODE: string = 'addresszip';
}
export class ScanIDLocationConstant {
    public static readonly SCAN_ID_CAPTURE_IMAGE: string = 'ScanIdCaptureImage';
    public static readonly SCAN_ID_OPTIONS: string = 'ScanIdOptions';
    public static readonly SCANI_ID_PRE_LANDING: string = 'ScanIdPreLanding';
}
