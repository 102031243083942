<div class="validation-hints">
    <span [innerHTML]="title"></span>
    <ul>
        @for (hint of validationsHints; track trackByClassName($index, hint)) {
            <li [ngClass]="hint.hintTextClass">
                <i [ngClass]="hint.hintIconClass"></i>
                <span [ngClass]="hint.className">{{ hint.message }}</span>
            </li>
        }
    </ul>
</div>
