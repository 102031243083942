import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptPrefixChar]',
})
export class PortalPrefixCharDirective {
    @Input('ptPrefixChar') prefix: string;
    constructor(private control: NgControl) {}

    @HostListener('keyup', ['$event'])
    onKeyUp() {
        const actual = this.control?.control?.value;
        if (!actual) this.control.control?.setValue(this.prefix);
        else if (actual?.length < this.prefix.length) this.control.control?.setValue(this.prefix);
        else if (actual.substr(0, this.prefix.length)?.toLowerCase() != this.prefix?.toLocaleLowerCase())
            this.control.control?.setValue(this.prefix + actual.substr(this.prefix.length - 1));
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const actual = this.control?.control?.value;
        if (actual?.toLowerCase() == this.prefix.toLocaleLowerCase()) {
            if (event.ctrlKey || event.altKey || event.metaKey || event.key == 'Backspace' || event.key == 'Delete') event.preventDefault();
        }
    }
}
