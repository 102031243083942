export class AccountLimit {
    amount: number | null;
    pendingLimit: number;
    approvedFormattedDate: string;
    originalAmount: number | null;
    limitType: string;
    isNoLimitSet: boolean;
    isPending: boolean;
    product: string;
    usedLimit: number;
    submittedDate: any;
    showTextBox: boolean;
    pendingLimitText: string;
    cancelClicked: boolean;
    progressbarWidth: number;
    amountDisplay: string;

    constructor(public limitName?: string) {}

    set(data: {
        amount?: number;
        pendingLimit?: number;
        approvedFormattedDate?: string;
        originalAmount?: number;
        limitType?: string;
        isNoStakeLimitPending?: boolean;
        isNoLimitSet?: boolean;
    }) {
        Object.assign(this, data);
        this.isPending = data.pendingLimit! > data.originalAmount! || data.isNoStakeLimitPending!;
        if (this.amount) this.amount = this.pendingLimit > 0 || data.isNoStakeLimitPending ? this.pendingLimit : this.amount > 0 ? this.amount : null;
    }

    isChanged() {
        if (!this.amount) {
            return false;
        }

        if (this.isPending) {
            return this.amount != this.pendingLimit;
        }

        return this.amount != this.originalAmount;
    }
}

export class AutoCashoutLimitsResponse {
    balanceThresholdAmount: number | null;
    balanceRemainingAmount: number | null;
}
