import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollToService {
    startingY: any;
    diff: any;
    start: any;
    duration: any;
    constructor() {}

    scrollTo(element: any, duration?: number, offset?: number, scrollIntoView?: boolean, scrollIntoViewOptions?: ScrollIntoViewOptions): void {
        if (duration === void 0) {
            duration = 500;
        }
        if (offset === void 0) {
            offset = 0;
        }
        if (typeof element === 'string') {
            const el = document.querySelector(element);
            if (scrollIntoView && el) el.scrollIntoView(scrollIntoViewOptions);
            else this.scrollToElement(el, duration, offset);
        } else if (element instanceof HTMLElement) {
            this.scrollToElement(element, duration, offset);
        }
    }

    private scrollToElement(el: any, duration: number, offset: number): void {
        if (el) {
            const viewportOffset = el.getBoundingClientRect();
            const offsetTop = viewportOffset.top + window.pageYOffset;
            this.doScrolling(offsetTop + offset, duration);
        }
    }

    private step(timestamp: any) {
        this.start = !this.start ? timestamp : this.start;
        const time = timestamp - this.start;
        const percent = Math.min(time / this.duration, 1);
        window.scrollTo(0, this.startingY + this.diff * percent);
        if (time < this.duration) {
            window.requestAnimationFrame((timestamp) => this.step(timestamp));
        } else {
            this.start = null;
        }
    }

    private doScrolling(elementY: any, duration: number): void {
        this.duration = duration;
        this.startingY = window.pageYOffset;
        this.diff = elementY - this.startingY;
        window.requestAnimationFrame((timestamp) => this.step(timestamp));
    }
}
