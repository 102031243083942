import { Injectable } from '@angular/core';

import {
    AppInfoConfig,
    CookieName,
    CookieService,
    DeviceService,
    NativeAppService,
    Page,
    TrackingService,
    UserService,
    ViewTemplateForClient,
    WindowRef,
} from '@frontend/vanilla/core';

import { ErrorDetail, EventNameConst, TrackingEvent } from './tracking.model';

@Injectable({
    providedIn: 'root',
})
export class PortalTrackingService {
    trackingEvents: TrackingEvent;
    constructor(
        private trackingService: TrackingService,
        private nativeAppService: NativeAppService,
        public page: Page,
        private user: UserService,
        private appInfoConfig: AppInfoConfig,
        public device: DeviceService,
        private windowRef: WindowRef,
        private cookieService: CookieService,
    ) {}

    triggerTrackingEvent(trackingEvents: TrackingEvent) {
        this.trackEvents(trackingEvents);
    }

    reportErrorObject(errorDetail: ErrorDetail) {
        this.trackingService.reportErrorObject(errorDetail);
    }

    trackEvents(trackingEvents: TrackingEvent, siteSection: string = '', EventName: string = '', overrideEvents: TrackingEvent = {}) {
        const siteSectionEvent = {
            'page.siteSection': siteSection,
        };
        let trackEvents = {
            'component.CategoryEvent': IsNullOrEmpty(trackingEvents.CategoryEvent) ? 'not applicable' : trackingEvents.CategoryEvent,
            'component.LabelEvent': IsNullOrEmpty(trackingEvents.LabelEvent) ? 'not applicable' : trackingEvents.LabelEvent,
            'component.ActionEvent': IsNullOrEmpty(trackingEvents.ActionEvent) ? 'not applicable' : trackingEvents.ActionEvent,
            'component.PositionEvent': IsNullOrEmpty(trackingEvents.PositionEvent) ? 'not applicable' : trackingEvents.PositionEvent,
            'component.LocationEvent': IsNullOrEmpty(trackingEvents.LocationEvent) ? 'not applicable' : trackingEvents.LocationEvent,
            'component.EventDetails': IsNullOrEmpty(trackingEvents.EventDetails) ? 'not applicable' : trackingEvents.EventDetails,
        };
        if (!IsNullOrEmpty(trackingEvents.Value)) {
            const valueTrackingEvents: ValueTrackingEvents = {
                'component.value': IsNullOrEmpty(trackingEvents.Value) ? 'not applicable' : trackingEvents.Value,
            };
            if (!IsNullOrEmpty(trackingEvents.Product)) {
                valueTrackingEvents['component.Product'] = trackingEvents.Product;
            }
            EventName = IsNullOrEmpty(EventName) ? 'Event.Value' : EventName;
            trackEvents = { ...trackEvents, ...valueTrackingEvents };
        } else {
            const eventTrackingEvents = {
                'component.URLClicked': IsNullOrEmpty(trackingEvents.UrlClicked) ? 'not applicable' : trackingEvents.UrlClicked,
            };
            trackEvents = { ...trackEvents, ...eventTrackingEvents };
        }
        if (!IsNullOrEmpty(trackingEvents.AffordabilityJourney)) {
            const journeyTrackingEvent = {
                'component.AffordabilityJourney': trackingEvents.AffordabilityJourney,
            };
            trackEvents = { ...trackEvents, ...journeyTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.AffAgeGroups)) {
            const affAgeGroupEvent = {
                'component.AffAgeGroups': trackingEvents.AffAgeGroups,
            };
            trackEvents = { ...trackEvents, ...affAgeGroupEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.AffordabilityGroups)) {
            const affordabilityGroupsEvent = {
                'component.AffordabilityGroups': 'group ' + trackingEvents.AffordabilityGroups,
            };
            trackEvents = { ...trackEvents, ...affordabilityGroupsEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.AffLimitType)) {
            const affLimitTypeEvent = {
                'component.AffLimitType': trackingEvents.AffLimitType,
            };
            trackEvents = { ...trackEvents, ...affLimitTypeEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.RegistrationType)) {
            const registrationTypeTrackingEvent = {
                'page.registrationType': trackingEvents.RegistrationType,
            };
            trackEvents = { ...trackEvents, ...registrationTypeTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.offerId)) {
            const offerIdTrackingEvent = {
                'page.offerId': trackingEvents.offerId,
            };
            trackEvents = { ...trackEvents, ...offerIdTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.ContentPosition)) {
            const ContentPositionTrackingEvent = {
                'component.ContentPosition': trackingEvents.ContentPosition,
            };
            trackEvents = { ...trackEvents, ...ContentPositionTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.RegistrationSourcePath)) {
            const registrationTypeTrackingEvent = {
                'page.registrationSourcePath': trackingEvents.RegistrationSourcePath,
            };
            trackEvents = { ...trackEvents, ...registrationTypeTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.Progress)) {
            const registrationTypeTrackingEvent = {
                'page.registrationProgress': trackingEvents.Progress,
            };
            trackEvents = { ...trackEvents, ...registrationTypeTrackingEvent };
        }
        if (!IsNullOrEmpty(overrideEvents)) {
            Object.keys(overrideEvents).map((key) => {
                const overriddenKey = 'component.' + key;
                trackEvents = {
                    ...trackEvents,
                    [overriddenKey]: IsNullOrEmpty(overrideEvents[key as keyof TrackingEvent])
                        ? 'not applicable'
                        : overrideEvents[key as keyof TrackingEvent],
                };
            });
        }
        if (EventName == EventNameConst.HelpAndContact) {
            const eventTrackingEvents = {
                'component.hccCategory': IsNullOrEmpty(trackingEvents.HccCategory) ? 'not applicable' : trackingEvents.HccCategory,
                'component.hccArticle': IsNullOrEmpty(trackingEvents.HccArticle) ? 'not applicable' : trackingEvents.HccArticle,
            };
            trackEvents = { ...trackEvents, ...eventTrackingEvents };
        }
        if (!IsNullOrEmpty(trackingEvents.LimitDetails)) {
            const limitDetailsEvent = { 'component.LimitDetails': trackingEvents.LimitDetails };
            trackEvents = { ...trackEvents, ...limitDetailsEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.UserAccountId)) {
            const userAccountIdTrackingEvent = {
                'user.profile.accountID': trackingEvents.UserAccountId,
            };
            trackEvents = { ...trackEvents, ...userAccountIdTrackingEvent };
        }
        if (trackingEvents.type == 'standard logout') {
            const userLogoutTrackingEvent = {
                'logout.type': trackingEvents.type,
            };
            trackEvents = { ...trackEvents, ...userLogoutTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.flowName)) {
            const expiryflow = {
                'component.flowName': trackingEvents.flowName,
            };
            trackEvents = { ...trackEvents, ...expiryflow };
        }
        if (!IsNullOrEmpty(siteSection) && IsNotEmptyObject(trackingEvents))
            this.trackingService.triggerEvent(EventName === '' ? 'Event.Tracking' : EventName, { ...siteSectionEvent, ...trackEvents });
        else if (IsNotEmptyObject(trackingEvents)) this.trackingService.triggerEvent(EventName === '' ? 'Event.Tracking' : EventName, trackEvents);
    }

    trackEventsWithoutVariables(trackingEvents: TrackingEvent, siteSection: string = '', EventName: string = '', overrideEvents: TrackingEvent = {}) {
        const siteSectionEvent = {
            'page.siteSection': siteSection,
        };
        let trackEvents = {};
        if (!IsNullOrEmpty(trackingEvents.CategoryEvent)) {
            const categoryTrackingEvent = {
                'component.CategoryEvent': trackingEvents.CategoryEvent,
            };
            trackEvents = { ...trackEvents, ...categoryTrackingEvent };
        }

        if (!IsNullOrEmpty(trackingEvents.LabelEvent)) {
            const labelTrackingEvent = {
                'component.LabelEvent': trackingEvents.LabelEvent,
            };
            trackEvents = { ...trackEvents, ...labelTrackingEvent };
        }

        if (!IsNullOrEmpty(trackingEvents.LabelEvent)) {
            const actionTrackingEvent = {
                'component.ActionEvent': trackingEvents.ActionEvent,
            };
            trackEvents = { ...trackEvents, ...actionTrackingEvent };
        }

        if (!IsNullOrEmpty(trackingEvents.LabelEvent)) {
            const positionTrackingEvent = {
                'component.PositionEvent': trackingEvents.PositionEvent,
            };
            trackEvents = { ...trackEvents, ...positionTrackingEvent };
        }

        if (!IsNullOrEmpty(trackingEvents.LabelEvent)) {
            const locationTrackingEvent = {
                'component.LocationEvent': trackingEvents.LocationEvent,
            };
            trackEvents = { ...trackEvents, ...locationTrackingEvent };
        }

        if (!IsNullOrEmpty(trackingEvents.LabelEvent)) {
            const eventTrackingEvent = {
                'component.EventDetails': trackingEvents.EventDetails,
            };
            trackEvents = { ...trackEvents, ...eventTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.Value)) {
            const valueTrackingEvents: ValueTrackingEvents = {
                'component.value': IsNullOrEmpty(trackingEvents.Value) ? 'not applicable' : trackingEvents.Value,
            };
            if (!IsNullOrEmpty(trackingEvents.Product)) {
                valueTrackingEvents['component.Product'] = trackingEvents.Product;
            }
            EventName = IsNullOrEmpty(EventName) ? 'Event.Value' : EventName;
            trackEvents = { ...trackEvents, ...valueTrackingEvents };
        } else {
            const eventTrackingEvents = {
                'component.URLClicked': !IsNullOrEmpty(trackingEvents.UrlClicked) ? 'not applicable' : trackingEvents.UrlClicked,
            };
            trackEvents = { ...trackEvents, ...eventTrackingEvents };
        }
        if (!IsNullOrEmpty(trackingEvents.AffordabilityJourney)) {
            const journeyTrackingEvent = {
                'component.AffordabilityJourney': IsNullOrEmpty(trackingEvents.AffordabilityJourney)
                    ? 'not applicable'
                    : trackingEvents.AffordabilityJourney,
            };
            trackEvents = { ...trackEvents, ...journeyTrackingEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.AffordabilityGroups)) {
            const affordabilitygroupData = trackingEvents.AffordabilityGroups;
            const affordabilityGroupsEvent = {
                'component.AffordabilityGroups': IsNullOrEmpty(trackingEvents.AffordabilityGroups)
                    ? 'not applicable'
                    : affordabilitygroupData?.toLocaleLowerCase() == 'not applicable'
                      ? 'not applicable'
                      : 'group ' + trackingEvents.AffordabilityGroups,
            };
            trackEvents = { ...trackEvents, ...affordabilityGroupsEvent };
        }
        if (!IsNullOrEmpty(trackingEvents.RegistrationType)) {
            const registrationTypeTrackingEvent = {
                'page.registrationType': trackingEvents.RegistrationType,
            };
            trackEvents = { ...trackEvents, ...registrationTypeTrackingEvent };
        }
        if (!IsNullOrEmpty(overrideEvents)) {
            Object.keys(overrideEvents).map((key) => {
                const overriddenKey = 'component.' + key;
                trackEvents = {
                    ...trackEvents,
                    [overriddenKey]: IsNullOrEmpty(overrideEvents[key as keyof TrackingEvent])
                        ? 'not applicable'
                        : overrideEvents[key as keyof TrackingEvent],
                };
            });
        }
        if (EventName == EventNameConst.HelpAndContact) {
            const eventTrackingEvents = {
                'component.hccCategory': IsNullOrEmpty(trackingEvents.HccCategory) ? 'not applicable' : trackingEvents.HccCategory,
                'component.hccArticle': IsNullOrEmpty(trackingEvents.HccArticle) ? 'not applicable' : trackingEvents.HccArticle,
            };
            trackEvents = { ...trackEvents, ...eventTrackingEvents };
        }
        if (!IsNullOrEmpty(siteSection) && IsNotEmptyObject(trackingEvents))
            this.trackingService.triggerEvent(EventName === '' ? 'Event.Tracking' : EventName, { ...siteSectionEvent, ...trackEvents });
        else if (IsNotEmptyObject(trackingEvents)) this.trackingService.triggerEvent(EventName === '' ? 'Event.Tracking' : EventName, trackEvents);
    }

    trackLegacyEvents(eventName: any, trackingEvents: TrackingEvent) {
        if (IsNotEmptyObject(trackingEvents)) {
            if (!IsNullOrEmpty(trackingEvents.SiteSection)) {
                this.trackingService.triggerEvent(eventName, {
                    'page.siteSection': trackingEvents.SiteSection,
                    'page.name': trackingEvents.PageName,
                });
            } else {
                this.trackingService.triggerEvent(eventName, {
                    'page.name': trackingEvents.PageName,
                });
            }
        }
    }
    trackArrayOfEvents(setLimits: any) {
        this.trackingService.triggerEvent('Event.OptionLoad', { setLimits });
    }
    //--Start Tracking if TrackingService/TrackingEvents site templates are available : Its dynamic tracking need to send track envent name mapped in site core and error message instead of event details(if its not dynamic).--//
    trackingDynamicService(
        trackEvents: ViewTemplateForClient,
        prefix: string = '',
        verificationStatus: any = undefined,
        daysLeft: string = '',
        attempts: any = undefined,
        screenName: any = '',
        attemptCountLeft: any = '',
    ) {
        if (trackEvents !== undefined && trackEvents.messages !== undefined) {
            const PositionEvent =
                trackEvents.messages[prefix + 'PositionEvent'] !== undefined && verificationStatus !== undefined
                    ? trackEvents.messages[prefix + 'PositionEvent'].replace('{0}', verificationStatus)
                    : trackEvents.messages[prefix + 'PositionEvent'] !== undefined
                      ? trackEvents.messages[prefix + 'PositionEvent']
                      : '';
            const EventDetails =
                trackEvents.messages[prefix + 'EventDetails'] !== undefined && screenName != ''
                    ? trackEvents.messages[prefix + 'EventDetails'].replace('{0}', screenName)
                    : trackEvents.messages[prefix + 'EventDetails'] !== undefined && attemptCountLeft
                      ? trackEvents.messages[prefix + 'EventDetails'].replace('{0}', attemptCountLeft)
                      : trackEvents.messages[prefix + 'EventDetails'] !== undefined && daysLeft
                        ? trackEvents.messages[prefix + 'EventDetails'].replace('{daysLeft}', daysLeft)
                        : trackEvents.messages[prefix + 'EventDetails'] !== undefined
                          ? trackEvents.messages[prefix + 'EventDetails']
                          : '';
            const LocationEvent =
                trackEvents.messages[prefix + 'LocationEvent'] !== undefined && screenName != ''
                    ? trackEvents.messages[prefix + 'LocationEvent'].replace('{0}', screenName)
                    : trackEvents.messages[prefix + 'LocationEvent'] !== undefined && attempts !== undefined
                      ? trackEvents.messages[prefix + 'LocationEvent'].replace('{0}', attempts)
                      : trackEvents.messages[prefix + 'LocationEvent'] !== undefined
                        ? trackEvents.messages[prefix + 'LocationEvent']
                        : '';
            this.trackingEvents = {
                CategoryEvent: trackEvents.messages[prefix + 'CategoryEvent'] !== undefined ? trackEvents.messages[prefix + 'CategoryEvent'] : '',
                LabelEvent: trackEvents.messages[prefix + 'LabelEvent'] !== undefined ? trackEvents.messages[prefix + 'LabelEvent'] : '',
                ActionEvent: trackEvents.messages[prefix + 'ActionEvent'] !== undefined ? trackEvents.messages[prefix + 'ActionEvent'] : '',
                PositionEvent: PositionEvent,
                LocationEvent: LocationEvent,
                EventDetails: EventDetails, //trackEvents.messages[prefix + 'EventDetails'] !== undefined ? trackEvents.messages[prefix + 'EventDetails'] : '',
                UrlClicked: trackEvents.messages[prefix + 'URLClicked'] !== undefined ? trackEvents.messages[prefix + 'URLClicked'] : '',
            };
            this.trackEvents(
                this.trackingEvents,
                trackEvents.messages[prefix + 'SiteSection'] !== undefined ? trackEvents.messages[prefix + 'SiteSection'] : '',
                trackEvents.messages[prefix + 'EventName'],
            );
        }
    }
    //--End Tracking--//
    //---API Trcking
    public getClientID(): any {
        if (
            this.windowRef.nativeWindow['ga']?.getAll &&
            typeof this.windowRef.nativeWindow['ga'].getAll === 'function' &&
            this.windowRef.nativeWindow['ga'].getAll()[0]
        ) {
            return this.windowRef.nativeWindow['ga'].getAll()[0].get('clientId');
        }

        return this.cookieService.get(CookieName.VisitIdCookieName) || '';
    }

    getGaParameter() {
        let gaparm: any = this.windowRef.nativeWindow['ga']?.getAll && this.windowRef.nativeWindow['ga']?.getAll()[0].get('linkerParam');
        if (gaparm) {
            gaparm = gaparm.toString().replace('_ga=', '');
            return gaparm;
        } else return null;
    }

    private getPageName() {
        return window.location?.pathname?.replace('/' + this.page.lang + '/', '')?.toLowerCase();
    }

    private getCookieValue(name: string) {
        return document.cookie
            ?.split(';')
            .find((x) => x.includes(name))
            ?.split('=')[1];
    }

    getSessionValue(sessionId: string) {
        const item: any = document.cookie?.split(';').find((x) => x.includes(sessionId));
        const sessionValue: any = item?.split('.')[2];
        return sessionValue?.match(/^\d{10}$/) ? sessionValue : '';
    }

    private async trackAPI(url: string, params: any, body: any) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '?' + new URLSearchParams(params));
        xhr.send(body);
    }

    trackRemote(trackingData: any) {
        const url = trackingData.configtrackingAPIData.endPoint['GA4'];
        const payload = this.getGA4Payload(trackingData);
        if (url && payload) this.trackAPI(url, payload.params, payload.body);
    }
    private getChannel() {
        let channelID = 'desktop';
        if (this.nativeAppService.isNative) {
            channelID = this.nativeAppService.applicationName;
        } else if (this.device.isMobile) channelID = 'mobile';
        return channelID;
    }

    //GA4
    private getGA4Payload(trackingData: any) {
        const params: any = {
            measurement_id: trackingData.configtrackingAPIData.gA4['MeasurementId'],
            api_secret: trackingData.configtrackingAPIData.gA4['APISecretKey'],
        };
        const data: any = {
            client_id: this.getClientID(),
            timestamp_micros: new Date().getTime() * 1000,
            user_id: trackingData.accountID,
            user_properties: {
                base_currency: {
                    value: this.user.currency?.toLowerCase(),
                },
                prof_country: {
                    value: this.user.country?.toLowerCase(),
                },
                appinfo: {
                    value: this.appInfoConfig.channel?.toLowerCase(),
                },
                prof_customerid: {
                    value: trackingData.accountID,
                },
                prof_loggedin: {
                    value: 'not_logged_in',
                },
                page_fe: {
                    value: this.getChannel(),
                },
            },
            events: [
                {
                    name: 'registration_success_api',
                    params: {
                        // tid: trackingData.configtrackingAPIData.gA4['MeasurementId'], //measurement id
                        ua: navigator.userAgent?.toLowerCase(),
                        user_agent: navigator.userAgent,
                        page_title: document.title,
                        page_location: document.URL,
                        page_referrer: document.referrer,
                        page_hostname: document.location.host,
                        ul: this.page.culture?.toLowerCase(),
                        session_id: this.getSessionValue(trackingData.configtrackingAPIData.gA4['SessionId']), // sesssion_id,
                        category_event: 'registration_api - ' + trackingData.RegistrationType, // {registration type e.g.conversational_registration}
                        label_event: this.getPageName()?.toLowerCase(),
                        action_event: 'success',
                        test_abtest: this.getCookieValue('gvc_expt'),
                        registration_progress: 100,
                        registration_source: trackingData.socaialreg,
                        registration_type: trackingData.RegistrationType,
                        clean_page_URL: document.location.origin + document.location.pathname,
                        custom_timestamp: new Date().getTime() * 1000,
                        page_name: '/registration_success',
                        page_site_section: 'registration',
                        // page_fe: this.getChannel(),
                    },
                },
            ],
        };
        const body = JSON.stringify(data);
        return { params, body };
    }
    //--API Tacking End
    changeArrayAsTracking(array: TrackingEvent[]) {
        const resultArray: any[] = [];
        array.map((s: TrackingEvent) => {
            resultArray.push({
                'component.CategoryEvent': IsNullOrEmpty(s.CategoryEvent) ? 'not applicable' : s.CategoryEvent,
                'component.LabelEvent': IsNullOrEmpty(s.LabelEvent) ? 'not applicable' : s.LabelEvent,
                'component.ActionEvent': IsNullOrEmpty(s.ActionEvent) ? 'not applicable' : s.ActionEvent,
                'component.PositionEvent': IsNullOrEmpty(s.PositionEvent) ? 'not applicable' : s.PositionEvent,
                'component.LocationEvent': IsNullOrEmpty(s.LocationEvent) ? 'not applicable' : s.LocationEvent,
                'component.EventDetails': IsNullOrEmpty(s.EventDetails) ? 'not applicable' : s.EventDetails,
                'component.value': IsNullOrEmpty(s.Value) ? 'not applicable' : s.Value,
                'component.Product': IsNullOrEmpty(s.Product) ? 'not applicable' : s.Product,
            });
        });
        return resultArray as any;
    }
    trackingEventsZendeskService(actionEvent: string, eventDetails: string) {
        this.trackEvents({
            CategoryEvent: 'hcc',
            LabelEvent: 'livechat',
            ActionEvent: actionEvent,
            EventDetails: eventDetails,
        });
    }

    trackingDomicileControlEvents(
        actionEvent: string,
        locationEvent: string,
        registrationType: string,
        positionEvent?: string,
        categoryEvent?: string,
        labelEvent?: string,
        eventDetails?: string,
    ) {
        const trackingEvents: TrackingEvent = {
            CategoryEvent: categoryEvent ? categoryEvent : 'registration process',
            LabelEvent: labelEvent ? labelEvent : 'registration field tracking',
            UrlClicked: 'not applicable',
            PositionEvent: positionEvent ? positionEvent : 'not applicable',
            EventDetails: eventDetails ? eventDetails : 'domicile address',
            ActionEvent: actionEvent,
            LocationEvent: locationEvent,
            RegistrationType: registrationType ? registrationType : null,
        };
        this.trackEvents(trackingEvents, '', '');
    }
}

function IsNullOrEmpty(value: any): boolean {
    return value == null || value.length === 0 || value == '' || value == undefined;
}

function IsNotEmptyObject(valueObject: any): boolean {
    return Object.keys(valueObject).length !== 0;
}

interface ValueTrackingEvents {
    'component.value': string | number | null | undefined;
    'component.Product'?: string | number | null | undefined;
    // Add more properties as needed
}
