import { Subject } from 'rxjs';

import { DynamicFormComponentConfiguration } from './dynamic-form-component-configuration';

export type BeforeSubmitHook = () => boolean | Promise<boolean>;
export type AfterSubmitHook = (data: any) => void | Promise<void>;

export class ModularFormContext {
    mainComponentConfiguration: DynamicFormComponentConfiguration | null;
    content: any;
    staticData: any;
    config: any;
    prefillData: any;
    events: ModularContextEventsBase = new ModularContextEventsBase();
    bootstrapSubComponent?: (component: any) => void;
    wait: boolean = false;
    private onBeforeSubmitHooks: BeforeSubmitHook[] = [];
    private onAfterSubmitHooks: AfterSubmitHook[] = [];

    constructor(public prefix: string) {}

    resetSubmitHooks() {
        this.onBeforeSubmitHooks = [];
        this.onAfterSubmitHooks = [];
    }

    registerBeforeSubmitHook(fn: BeforeSubmitHook) {
        this.onBeforeSubmitHooks.push(fn);
    }

    registerAfterSubmitHook(fn: AfterSubmitHook) {
        this.onAfterSubmitHooks.push(fn);
    }

    submitWithHooks(fn: () => Promise<any>): Promise<void> {
        return this.runBeforeSubmitHooks().then((hooksResult) => {
            if (hooksResult) {
                this.wait = true;
                return fn()
                    .then((data) => this.runAfterSubmitHooks(data))
                    .then(() => {
                        this.wait = false;
                    })
                    .catch((err) => {
                        this.wait = false;
                        return Promise.reject(err);
                    });
            }
            return;
        });
    }

    private runBeforeSubmitHooks() {
        return Promise.all(this.onBeforeSubmitHooks.map((h) => h())).then((result) => result.every((x) => x));
    }

    private runAfterSubmitHooks(data: any) {
        return Promise.all(this.onAfterSubmitHooks.map((h) => h(data)));
    }
}

export class ModularContextEventsBase {
    formInitDone: Subject<void> = new Subject<void>();
}
