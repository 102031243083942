import { Directive, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { DateModel } from '@frontend/vanilla/shared/forms';

import { ValidatorsExtended } from '../forms/validators-extended';
@Directive({
    selector: '[ptCheckCnp]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CheckCnpValidatorDirective, multi: true }],
})
export class CheckCnpValidatorDirective implements OnChanges, OnInit, Validator {
    @Input() ptCheckCnp: {
        improvedValidation?: boolean;
        skipGenderCheck?: boolean;
        gender?: 'Male' | 'Mr' | 'Female' | 'Ms' | 'Mrs';
        dateOfBirth?: string | Date | DateModel;
    };
    onChange: () => void;

    private onValidate: (c: AbstractControl) => { [key: string]: any } | null;

    ngOnInit(): void {
        if (!this.ptCheckCnp) {
            this.ptCheckCnp = {};
        }
        if (this.ptCheckCnp.improvedValidation === undefined) {
            this.ptCheckCnp.improvedValidation = false; // skips the gender check by default
        }
        if (this.ptCheckCnp.skipGenderCheck === undefined) {
            this.ptCheckCnp.skipGenderCheck = true; // skips the gender check by default
        }
        this.onValidate = ValidatorsExtended.validCnp(
            this.ptCheckCnp.improvedValidation,
            this.ptCheckCnp.skipGenderCheck,
            this.dobProvider,
            this.genderProvider as any,
        );
    }

    validate(c: AbstractControl): { [key: string]: any } | null {
        return this.onValidate(c);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const ptCheckCnpChange = changes['ptCheckCnp'];
        if (ptCheckCnpChange && !ptCheckCnpChange.firstChange) {
            const current = ptCheckCnpChange.currentValue;
            const previous = ptCheckCnpChange.previousValue;
            if (current.skipGenderCheck !== undefined && current.skipGenderCheck !== previous.skipGenderCheck) {
                this.onValidate = ValidatorsExtended.validCnp(
                    current.improvedValidation,
                    current.skipGenderCheck,
                    this.dobProvider,
                    this.genderProvider as any,
                );
            }
        }

        if (this.onChange) {
            this.onChange();
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onChange = fn;
    }

    private dobProvider = () => (this.ptCheckCnp && this.ptCheckCnp.dateOfBirth ? this.tryParseToJson(this.ptCheckCnp.dateOfBirth) : null);

    private genderProvider = () => (this.ptCheckCnp && this.ptCheckCnp.gender ? this.ptCheckCnp.gender : null);

    private tryParseToJson(value: string | Date | DateModel) {
        try {
            if (value instanceof String) {
                return JSON.parse(value.toString());
            } else if (value instanceof Date) {
                return {
                    day: value.getDate().toFixed(0),
                    month: (value.getMonth() + 1).toFixed(0),
                    year: value.getFullYear().toFixed(0),
                };
            }

            return value;
        } catch (e) {
            return value;
        }
    }
}
