import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ptAlphabetsOnly]',
})
export class AlphabetsAllowedOnlyDirective {
    private pattern = /^[a-zA-Z ]+$/g;
    constructor(private control: NgControl) {}

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent) {
        const element = event.target as HTMLInputElement;
        const newValue = element.value.replace(this.pattern, '');
        if (newValue == element.value) {
            this.control.control!.setValue(newValue);
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key && event.key.length === 1 && !event.key.match(this.pattern)) {
            event.preventDefault();
        }
    }
}
