<div
    class="form-group form-group-col form-control-f-w no-padding flexbox flex-wrap d-flex pr-0"
    [ngClass]="{ 'form-row': !enableThreeStepRegUIEnhancements, 'ui-enhancements-form-row': enableThreeStepRegUIEnhancements }"
    [class]="layoutClass">
    @for (subComponent of component.subComponents; track $index) {
        <div class="form-group-col w-50" [ngClass]="subComponent.parameters ? subComponent.parameters.containerCssClass : ''">
            <pt-modular-sub-component-host [component]="subComponent" [parentForm]="parentForm" [context]="context"></pt-modular-sub-component-host>
        </div>
    }
</div>
