import { Injectable, Type } from '@angular/core';

import { ModularSubComponentBase } from './modular-sub-component-base';

@Injectable({
    providedIn: 'root',
})
export class ModularSubComponentRegistryService {
    static registry: Map<string, Type<ModularSubComponentBase>> = new Map<string, Type<ModularSubComponentBase>>([]);

    static register(name: string, type: Type<ModularSubComponentBase>, prefix: string | null = null) {
        this.registry.set(prefix + name, type);
    }

    get(prefix: string, name: string): Type<ModularSubComponentBase> | undefined {
        return ModularSubComponentRegistryService.registry.get(prefix + name) || ModularSubComponentRegistryService.registry.get(name);
    }
}

@Injectable({
    providedIn: 'root',
})
export class ModularSubComponentRegistryServiceNew {
    private registry: Map<string, Type<ModularSubComponentBase>> = new Map<string, Type<ModularSubComponentBase>>([]);

    register(name: string, type: Type<ModularSubComponentBase>, prefix: string | null = null) {
        if (!(ModularSubComponentRegistryService.registry.get(prefix + name) || ModularSubComponentRegistryService.registry.get(name)))
            this.registry.set(prefix + name, type);
    }

    get(prefix: string, name: string): Type<ModularSubComponentBase> | undefined {
        return this.registry.get(prefix + name) || this.registry.get(null + name);
    }
}

export function GenericSubComponent(componentName: string) {
    return (target: any) => {
        ModularSubComponentRegistryService.registry.set(componentName, target);
    };
}
