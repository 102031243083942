import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CircularProgressComponent } from './circular-progress.component';
import { StatusColorDirective } from './directives/status-color.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [CircularProgressComponent, StatusColorDirective],
    exports: [CircularProgressComponent, StatusColorDirective],
})
export class CircularProgressModule {}
