import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { UserService } from '@frontend/vanilla/core';
import {
    PortalFormGroup,
    ServerFieldValidationViolation,
    ValidationHelperService as ToolboxValidationHelperService,
    ValidationConfig,
    ValidationFactories,
    ValidationRuleSet,
    ValidationRuleSetTyped,
    Validators,
} from '@frontend/vanilla/shared/forms';
import clone from 'lodash-es/clone';

import { PasswordConfig } from '../client-config/client-config.models';
import { PersonalInformation, ZipProperties } from '../models/models';
import { DomicileAddressConstant } from '../registration/constants/registration-constants';
import { ValidatorsExtended } from './validators-extended';

@Injectable({
    providedIn: 'root',
})
export class ValidationHelperService {
    constructor(
        private validationConfig: ValidationConfig,
        private passwordConfig: PasswordConfig,
        private datePipe: DatePipe,
        private validationHelperService: ToolboxValidationHelperService,
        private userService: UserService,
    ) {}

    createDateNotUsedInPasswordValidator(passwordField: string): ValidatorFn {
        return ValidatorsExtended.dateNotUsedInPassword(passwordField, this.passwordConfig.invalidBirthdayFormats, (d, f) =>
            this.datePipe.transform(d, f),
        );
    }
    createPeselNumberValidators(): ValidatorFn[] {
        return [ValidatorsExtended.validPeselNumber()];
    }

    createNewDateNotUsedInPasswordValidator(passwordField: string, charOnEmptySpace: string = ''): ValidatorFn {
        return ValidatorsExtended.newDateNotUsedInPassword(
            passwordField,
            this.passwordConfig.invalidBirthdayFormats,
            (d, f) => this.datePipe.transform(d, f),
            charOnEmptySpace,
        );
    }

    createPasswordValidators(charOnEmptySpace: string | null = '__', passwordConfig?: any): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            firstName: this.userService.firstName,
            lastName: this.userService.lastName,
            email: this.userService.email,
            dateOfBirth: this.userService.dateOfBirth,
            username: this.userService.claims.get('name'),
        };

        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsLetters), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacters'),
            ValidatorsExtended.validPassword(
                passwordConfig ? passwordConfig.invalidBirthdayFormats : this.passwordConfig.invalidBirthdayFormats,
                (d, f) => this.datePipe.transform(d, f),
                personalInformation,
                charOnEmptySpace,
            ),
        ];
    }

    restricedSpecialChar(): any {
        return this.validationConfig.regexList.specialCharacterPassword;
    }

    createNewPasswordValidators(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            firstName: this.userService.firstName,
            lastName: this.userService.lastName,
            email: this.userService.email,
            dateOfBirth: this.userService.dateOfBirth,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsLetters), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacters'),
            ValidatorsExtended.validPassword(
                this.passwordConfig.invalidBirthdayFormats,
                (d, f) => this.datePipe.transform(d, f),
                personalInformation,
            ),
        ];
    }
    createNickNameValidationSettings(): ValidatorFn[] {
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.startWithALetter), 'startwithaletter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.screenNameSpecialCharacters), 'screennamespecialcharacters'),
        ];
    }
    createPasswordValidatorsWithNewSettings(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            email: this.userService.email,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsLetters), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacters'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperLowerCaseOrUpperSpecialOrLowerSpcialChar), 'upperlowercase'),
            this.validPasswordNew(personalInformation),
        ];
    }

    createPasswordValidatorsWithPersonalInformation(passwordConfig?: any): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            firstName: this.userService.firstName,
            lastName: this.userService.lastName,
            email: this.userService.email,
            dateOfBirth: this.userService.dateOfBirth,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsLetters), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacters'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperLowerCaseOrUpperSpecialOrLowerSpcialChar), 'upperlowercase'),
            ValidatorsExtended.validPassword(
                passwordConfig ? passwordConfig.invalidBirthdayFormats : this.passwordConfig.invalidBirthdayFormats,
                (d, f) => this.datePipe.transform(d, f),
                personalInformation,
            ),
        ];
    }
    createDePasswordValidationsWithPersonalInformation(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            firstName: this.userService.firstName,
            lastName: this.userService.lastName,
            email: this.userService.email,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperAndLowerCase), 'onlyupperlowercase'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharactersAllowed), 'specialcharactersallowed'),
            this.validatePasswordError(personalInformation),
        ];
    }
    createDeQuickRegistrationPasswordValidationsWithPersonalInformation(firstName: any, lastName: any, email: any, username: any): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: username,
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperAndLowerCase), 'onlyupperlowercase'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharactersAllowed), 'specialcharactersallowed'),
            this.validatePasswordError(personalInformation),
        ];
    }

    createPasswordValidatorsWithNewHints(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            email: this.userService.email,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsLetters), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharactersNotAllowed), 'specialcharacters'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.bothUpperAndLowerCase), 'upperlowercase'),
            this.validPasswordNew(personalInformation),
        ];
    }

    createSimplifiedPasswordHints(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            email: this.userService.email,
            username: this.userService.claims.get('name'),
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperAndLowerLetter), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacter'),
            this.validPasswordNew(personalInformation),
        ];
    }

    createSimplifiedPasswordHintsWithoutUserId(): ValidatorFn[] {
        const personalInformation: PersonalInformation = {
            email: this.userService.email,
        };
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.containsDigits), 'digit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.upperAndLowerLetter), 'letter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.specialCharacters), 'specialcharacter'),
            this.validPasswordNew(personalInformation),
        ];
    }

    applyPostalCodeValidation(
        formControl: AbstractControl | null,
        zipProperties: ZipProperties,
        validationMessages: { [key: string]: string } | undefined,
        improveValidationsEnabled: boolean = false,
    ): { validationMessages: { [key: string]: string } | undefined } {
        if (formControl) {
            const valid = this.validationHelperService.createValidators('addresszip', {
                overrides: zipProperties,
                validationFactoryOverrides: { regex: (pattern: string) => Validators.customPattern(new RegExp(pattern), 'postalCode') },
            });
            formControl.setValidators(valid);
            if (improveValidationsEnabled)
                formControl.setValidators([
                    ValidatorsExtended.notUsedInOtherFieldExactAddress1('addressline1'),
                    ValidatorsExtended.notUsedInOtherFieldExactCity('addresscity'),
                ]);
            formControl.updateValueAndValidity();
        }
        const validation = clone(validationMessages);
        let stringLengthMessage: string;

        if (zipProperties.minLength === zipProperties.maxLength) {
            stringLengthMessage = (validation!['StringLengthMax'] || '').replace('{maxlength}', zipProperties.maxLength + '');
        } else {
            stringLengthMessage = (validation!['StringLength'] || '')
                .replace('{maxlength}', zipProperties.maxLength + '')
                .replace('{minlength}', zipProperties.minLength + '');
        }

        validation!['StringLength'] = stringLengthMessage;

        return { validationMessages: validation };
    }

    applyDomicilePostalCodeValidation(
        formControl: AbstractControl | null,
        zipProperties: ZipProperties,
        validationMessages: { [key: string]: string } | undefined,
        improveValidationsEnabled: boolean = false,
    ): { validationMessages: { [key: string]: string } | undefined } {
        if (formControl) {
            const valid = this.validationHelperService.createValidators(DomicileAddressConstant.DOMICILE_ADDRESS_ZIP, {
                overrides: zipProperties,
                validationFactoryOverrides: { regex: (pattern: string) => Validators.customPattern(new RegExp(pattern), 'postalCode') },
            });
            formControl.setValidators(valid);
            if (improveValidationsEnabled)
                formControl.setValidators([
                    ValidatorsExtended.notUsedInOtherFieldExactAddress1(DomicileAddressConstant.DOMICILE_ADDRESS_LINE1),
                    ValidatorsExtended.notUsedInOtherFieldExactCity(DomicileAddressConstant.DOMICILE_ADDRESS_CITY),
                ]);
            formControl.updateValueAndValidity();
        }
        const validation = clone(validationMessages);
        let stringLengthMessage: string;
        if (zipProperties.minLength === zipProperties.maxLength) {
            stringLengthMessage = (validation!['StringLengthMax'] || '').replace('{maxlength}', zipProperties.maxLength + '');
        } else {
            stringLengthMessage = (validation!['StringLength'] || '')
                .replace('{maxlength}', zipProperties.maxLength + '')
                .replace('{minlength}', zipProperties.minLength + '');
        }
        validation!['StringLength'] = stringLengthMessage;
        return { validationMessages: validation };
    }

    createValidators(
        formControlName: string,
        options?: {
            overrides?: ValidationRuleSetTyped | null;
            validationFactoryOverrides?: Partial<ValidationFactories>;
            baseOverrides?: ValidationRuleSet;
            [propName: string]: any;
        },
    ): ValidatorFn[] {
        return this.validationHelperService.createValidators(formControlName, options as any);
    }

    getRules(formControlName: string): ValidationRuleSetTyped | null {
        return this.validationHelperService.getRules(formControlName);
    }

    getRawRules(formControlName: string) {
        return this.validationHelperService.getRawRules(formControlName);
    }

    applyViolations(form: UntypedFormGroup, violations?: ServerFieldValidationViolation[]) {
        if (violations) return this.validationHelperService.applyViolations(form, violations);
        return;
    }

    createNieValidators(): ValidatorFn[] {
        return [ValidatorsExtended.validateNie()];
    }

    createNifValidators(): ValidatorFn[] {
        return [ValidatorsExtended.validateNif()];
    }

    createStartWithALetter(): ValidatorFn[] {
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.startWithALetter), 'startwithaletter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.username), 'regularexpressionusername'),
        ];
    }

    //New username hint vailidation implemented for USNJ labels
    createNewUserNameHintValidation(): ValidatorFn[] {
        return [
            Validators.customPattern(new RegExp(this.validationConfig.regexList.startWithALetter), 'startwithaletter'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.userNameLetterDigit), 'usernameletterdigit'),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.userNameSpecialCharacters), 'usernamespecialcharacters'),
        ];
    }

    createMlifeIdValidation(): ValidatorFn[] {
        return [
            Validators.required,
            Validators.minLength(parseInt(this.validationConfig.rules.mlifeId.minLength)),
            Validators.maxLength(parseInt(this.validationConfig.rules.mlifeId.maxLength)),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.mlifeDigits), 'mlifeiddigit'),
        ];
    }

    createMlifePinValidation(): ValidatorFn[] {
        return [
            Validators.required,
            Validators.minLength(parseInt(this.validationConfig.rules.mlifePin.minLength)),
            Validators.maxLength(parseInt(this.validationConfig.rules.mlifePin.maxLength)),
            Validators.customPattern(new RegExp(this.validationConfig.regexList.digits), 'mlifepindigit'),
        ];
    }

    /**
     *
     * @param isCorrect if password is incorrect from backend
     * @description set password as incorrect manually if getting 600 error from backend.
     */
    setPasswordAsIncorrect(isCorrect: boolean): ValidatorFn {
        return (): ValidationErrors => {
            return { IncorrectPassword: isCorrect };
        };
    }

    //New password validation implemented. Now user can't user whole emailId and Username in password. Part of email or username can be used.
    validPasswordNew(personalInformation?: PersonalInformation) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || control.value.length === 0) {
                return null;
            }
            personalInformation = personalInformation || {};
            const email: string = getOtherFieldValue(control, 'emailaddress') || personalInformation.email;
            return validatePasswordNew(control.value, [email, getOtherFieldValue(control, 'username') || personalInformation.username]);
        };
    }

    validatePasswordError(personalInformation?: PersonalInformation) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || control.value.length === 0) {
                return null;
            }
            personalInformation = personalInformation || {};
            let email: string = getOtherFieldValue(control, 'emailaddress') || personalInformation.email;
            if (email && email.includes('@') && !this.passwordConfig.enableProgressbar) {
                email = email.substring(0, email.indexOf('@'));
            }
            return validatePasswordCannotPartofFields(control.value, [
                email,
                getOtherFieldValue(control, 'username') || personalInformation.username,
                getOtherFieldValue(control, 'firstname') || personalInformation.firstName,
                getOtherFieldValue(control, 'lastname') || personalInformation.lastName,
            ]);
        };
    }
    validateFromAndToDates(fromDate: Date, toDate: Date, messages: any) {
        if (fromDate.getFullYear().toPrecision().length != 4 || toDate.getFullYear().toPrecision().length != 4) {
            return messages['Error_Year'];
        } else if (fromDate > new Date(Date.now()) || toDate > new Date(Date.now())) {
            return messages['Error_fromDateToDateGreaterThanToday'];
        } else if (fromDate > toDate) {
            return messages['Error_EqualToToday'];
        } else {
            return null;
        }
    }
    replaceValidationKeys(rules: ValidationRuleSetTyped, messages?: { [key: string]: string }) {
        const messsagesCopy: { [key: string]: string } = {};
        if (messages && Object.keys(messages).length > 0 && rules) {
            for (const key in messages) {
                let message = messages[key];
                for (const rule in rules) {
                    if (rules[rule as keyof ValidationRuleSetTyped]) {
                        message = message.replace(`{${rule?.toLowerCase()}}`, <string>rules[rule as keyof ValidationRuleSetTyped]);
                    }
                }
                messsagesCopy[key] = message;
            }
            return messsagesCopy;
        }
        return messages;
    }
    /**
     *
     * @param component component object configured in composition, which contains 'additionalInfo.customValidation' property
     * @returns ValidatorFn[] | Undefined
     */
    getCustomValidators(component?: { [key: string]: any }) {
        const validateFn: ValidatorFn[] = [];
        if (component?.additionalInfo?.customValidation) {
            Object.keys(component?.additionalInfo?.customValidation).map((key: string) => {
                validateFn.push(
                    Validators.customPattern(new RegExp(this.validationConfig.regexList[component?.additionalInfo?.customValidation[key]]), key),
                );
            });
            return validateFn;
        }
        return validateFn;
    }

    checkCapslock(event: any) {
        if (event.getModifierState('CapsLock')) {
            return true;
        } else {
            return false;
        }
    }

    checkPwdValidation(event: any, minLength: boolean, validationMsg: any, vaildationObj: { [key: string]: boolean }) {
        if (event !== '' && event !== undefined) {
            vaildationObj.hasOwnProperty('upper') ? (vaildationObj['upper'] = /[A-Z]+/.test(event.target.value)) : false;
            vaildationObj.hasOwnProperty('lower') ? (vaildationObj['lower'] = /[a-z]+/.test(event.target.value)) : false;
            vaildationObj.hasOwnProperty('number') ? (vaildationObj['number'] = /[0-9]+/.test(event.target.value)) : false;
            vaildationObj.hasOwnProperty('length') ? (vaildationObj['length'] = minLength) : false;
            const progressBarStrength = (100 / Object.values(vaildationObj).length) * Object.values(vaildationObj).filter(Boolean).length;
            const checkValidation = Object.keys(vaildationObj)
                .filter((key) => !vaildationObj[key])
                .join('');
            const validationMessage = progressBarStrength == 100 ? validationMsg.fulfilled : validationMsg[checkValidation];
            const validationResult = {
                progressBarStrength: progressBarStrength,
                validationMsg: validationMessage,
            };
            return validationResult;
        }
        return;
    }
}

function getOtherFieldValue(control: AbstractControl, field: string) {
    const otherField = getOtherField(control, field);
    return otherField ? otherField.value : null;
}

function getOtherField(control: AbstractControl, field: string) {
    const root = control.root;
    let otherField: AbstractControl | null;
    if (root instanceof PortalFormGroup) {
        otherField = root.getFlat(field);
    } else {
        otherField = root.get(field);
    }
    if (!otherField) {
        return null;
    }
    return otherField;
}

function validatePasswordNew(password: string, stringValues: string[]) {
    password = password.toLowerCase();
    if (
        stringValues
            .filter((s) => s)
            .map((s) => s.toLowerCase())
            .some((s) => password.includes(s))
    ) {
        return { validPassword: true };
    }
    return null;
}
function validatePasswordCannotPartofFields(password: string, stringValues: string[]) {
    password = password.toLowerCase();
    if (
        stringValues
            .filter((s) => s)
            .map((s) => s.toLowerCase())
            .some((s) => password.includes(s))
    ) {
        return { validpassword: true };
    }
    return null;
}
