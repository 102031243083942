<div class="circular-progress-chart grace-period">
    <svg viewBox="0 0 34 34">
        <path
            class="circle-bg"
            d="M17 1
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path
            [input]="leftGraceDays"
            [colorStatusObj]="colorStatusObj"
            ptStatusColor
            class="circle-progress"
            bind-attr.stroke-dasharray="graceDaysPercentage"
            d="M17 1
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
        <circle [input]="leftGraceDays" [colorStatusObj]="colorStatusObj" ptStatusColor cx="50%" cy="1" r="2.9"></circle>
        <text
            ngClass="{ {{ overriddenStyleClass }} : overRideTopStyleOrNot}"
            x="50%"
            y="40%"
            class="progress-text"
            dominant-baseline="central"
            text-anchor="middle">
            {{ textTop }}
        </text>
        <text x="50%" y="70%" class="progress-text percentage-days-left" dominant-baseline="central" text-anchor="middle">{{ textBottom }}</text>
    </svg>
</div>
